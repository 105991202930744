<template>
  <section v-if="partner" class="partner-banner">
    <div>
      <div v-if="partner.bannerText" v-html="partner.bannerText" />
      <img
        v-if="partner.logo"
        :src="partner.logo"
        class="object-contain w-full h-full"
        :alt="`${partner.name} logo`"
      />
    </div>
  </section>
  <OLAboveFold
    :header="heroSection.header"
    :header-class="partner ? 'w-full' : 'w-full lg:w-1/2'"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full md:w-3/4"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="ctaUrl"
    :fold-bg-colour="heroSection.foldBgColour"
    :image="heroSection.image"
    :class="{ '!pt-4': partner }"
  />
  <BrochureSteps :header="stepsSection.title" :steps="stepsSection.steps" />
  <BrochureProducts
    v-if="partner"
    background-color="bg-ol-primary-10"
    :products="productSection"
    class="mb-2 sm:mb-3"
  />
  <ExpertsCard
    class="!p-0"
    heading="Need a helping hand?"
    paragraph="You can ask our expert team who will support you every step of the way."
    :headshots="['laura', 'eliza', 'sam', 'dylan', 'katie']"
  />
  <BetterValue :partner="partner" />
  <SlidingText :sliding-paragraphs="slidingText" />
  <SamsStory :title="samsStory.title" :body="samsStory.body" />
  <OLTrustpilotReviews />
  <OLFaqs
    heading="Death comes with all sorts of questions. Existential, emotional, logistical. Whatever questions you have, we can stretch apart together."
    :faqs="faqs"
  />
  <SectionCta />
</template>

<script setup>
import OLAboveFold from '@components/OLAboveFold.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import BetterValue from '@components/Brochure/BetterValue.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SamsStory from '@components/SamsStory.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import OLFaqs from '@components/OLFaqs.vue';
import SlidingText from '@components/Brochure/SlidingText.vue';
import BrochureProducts from '@components/Brochure/BrochureProducts.vue';

const props = defineProps({
  partner: {
    type: Object,
    default: null,
  },
  ctaUrl: {
    type: Object,
    default: null,
  },
  faqs: {
    type: Object,
    required: true,
  },
});

/** AboveFold hero section data (checks for partner data) */
const heroSection = props.partner
  ? {
      header:
        props.partner.hero.header ??
        `Claim your free will and support ${props.partner.name}`,
      subHeader:
        props.partner.hero.subHeader ??
        `In return, all we ask is that you consider leaving them a gift in your will. Simply use coupon code ${props.partner.code} at checkout.`,
      ctaText: "Let's get started",
      foldBgColour: 'bg-ol-primary-10',
    }
  : {
      header: 'Write a will that’s tailored to you.',
      subHeader: 'How will you make it your own?',
      ctaText: "Let's get started",
      caption: 'Our Charity Partnerships Manager, Suzanne’s late grandad',
      foldBgColour: 'bg-ol-secondary-50',
      image: {
        url: new URL(
          '/public/theme/brochure/images/hero_images/online_will_hero.avif',
          import.meta.url
        ),
        alt: 'Will writing Hero Image',
      },
    };

/** 3-steps section data */
const stepsSection = {
  title: 'Write your online will in 3 simple steps',
  steps: [
    {
      name: 'This is where your legacy starts',
      description:
        'We’ll help you write your will by asking questions to help work out what you want.',
    },
    {
      name: 'Over to our will experts',
      description:
        'They’ll review it within 10 working days. Then you’ll sign it to make it legally binding.',
    },
    {
      name: 'Keep on building your legacy',
      description:
        'You can update your will whenever you like, for free in your first year, and only £10 a year after that.',
    },
  ],
};

/** Product section data (if partner exists) */
const productSection = props.partner
  ? [
      {
        title: 'Our basic and bespoke will services',
        image: 'penPaper',
        alt: 'Illustration of pen and paper',
      },
      {
        title: 'Getting a fast-tracked will',
        image: 'arrowTarget',
        alt: 'Illustration of arrow striking a target',
      },
      {
        title: 'Writing a mirror will',
        image: 'twoPages',
        alt: 'Illustration of two pages with text on',
      },
      {
        title: 'Our will storage and update service',
        image: 'closedSafe',
        alt: 'Illustration of a closed safe',
      },
    ]
  : null;

/** Text for the sliding text component */
const slidingText = [
  {
    text: 'When people think of wills, they think about things like wealth, assets, property.',
    class: 'md:w-2/3',
  },
  {
    text: "What secret don't you want to take to your grave?",
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'If you could relive one memory, what would it be?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'What should everyone wear at your funeral?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'What songs are on the playlist of your life?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
];

/** Text for Sams Story section */
const samsStory = {
  title: 'A will isn’t for you – you’ll never use it.',
  body: "It is for the people you leave behind. We know the difference a good plan makes and what it's like when there isn't one.\n\nIn 2016 our founder Sam's Mum died suddenly. It was tough enough, but the frustration of legal fees and mess that came with it was overwhelming.\n\nThat's why we started Octopus Legacy, so no one else has to go through the same thing.",
};
</script>

<style lang="scss" scoped>
section.partner-banner {
  @apply flex
  justify-center
  w-full
  bg-ol-primary-10
  rounded-3xl
  mt-4;

  > div {
    @apply flex
    flex-col-reverse lg:flex-row
    justify-between
    items-center
    w-full
    gap-6
    max-w-content
    p-6;

    > img {
      @apply w-48 h-20;
    }

    > div > * {
      @apply text-center lg:text-left max-w-160;
    }
  }
}
</style>
