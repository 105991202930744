<template>
  <OLCtaSection
    :header="sectionCta.heading"
    :content="sectionCta.subheading"
    :button-route="sectionCta.route"
    :button-text="sectionCta.buttonLabel"
    :image-bg="imageBg"
  />
</template>

<script setup>
import { useCtaDetails } from '../../composables/useCtaDetails';
import OLCtaSection from '@components/OLCtaSection.vue';

const { sectionCta } = useCtaDetails();

const props = defineProps({
  imageBg: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss" scoped></style>
