<template>
  <section class="core-values-section">
    <div class="core-values-heading-container">
      <h2 class="core-values-heading-container__heading">
        {{ heading }}
      </h2>
      <p class="core-values-heading-container__description">
        {{ description }}
      </p>
    </div>
    <div class="value-container">
      <div v-for="coreValue in values" class="font-bold flex flex-col gap-3">
        <component :is="coreValue.icon" class="h-40 self-start lg:mb-5" />
        <h1 class="text-xl font-bold">
          {{ coreValue.title }}
        </h1>
        <p class="text-base lg:text-lg font-normal">
          {{ coreValue.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Lemonade from '@js/components/Icon/Lemonade.vue';
import LightBulbs from '@js/components/Icon/LightBulbs.vue';
import Kite from '@js/components/Icon/Kite.vue';
import { ref } from 'vue';

export default {
  components: { Lemonade, LightBulbs, Kite },

  setup() {
    const heading = ref('Our core values');
    const description =
      ref(`Each value guides our culture, aligns people, and focuses on products and customers.
      They influence choices in identity, actions, and hiring.
      They’re ingrained in our DNA as we evolve and grow.`);
    const values = ref([
      {
        title: 'Optimism',
        description:
          'Bringing death to life is about being a glass-half-fuller. Success is easy when you focus on the things that matter.',
        icon: 'Lemonade',
      },
      {
        title: 'Hard working',
        description:
          'There’s no such thing as great success without hard work. We’re curious - always learning, testing and improving.',
        icon: 'LightBulbs',
      },
      {
        title: 'Customer first',
        description:
          'We’re obsessed with meeting our customers needs and delivering the best experience and value for them.',
        icon: 'Kite',
      },
    ]);
    return { heading, description, values };
  },
};
</script>

<style lang="scss" scoped>
.core-values-section {
  @apply bg-ol-accent-2-10 rounded-3xl text-ol-dark-ink-200
  px-5 xs:px-8 lg:px-32
  py-20 lg:py-28 xl:py-36;
}

.core-values-heading-container {
  @apply flex flex-col gap-5 mb-10 lg:mb-20  md:pr-56 lg:pr-0 lg:text-center max-w-screen-lg mx-auto;

  &__heading {
    @apply text-3xl md:text-4xl font-bold;
  }

  &__description {
    @apply text-base md:text-lg xl:text-xl text-balance md:text-wrap 2xl:text-balance;
  }
}

.value-container {
  @apply flex lg:max-w-screen-lg mx-auto justify-around
  flex-col lg:flex-row
  gap-y-14
  lg:gap-x-11 xl:gap-x-16 2xl:gap-x-20
  md:pr-56 lg:pr-0;
}
</style>
