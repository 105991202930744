<template xmlns="http://www.w3.org/1999/html">
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-1/2"
    :sub-header="heroSection.subHeader"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="route('will.typeform-onboarding', { utmParams })"
    :fold-bg-colour="heroSection.foldBgColour"
    :image="heroSection.image"
    :post-call-us="WillPricing"
  />
  <BrochureSteps :header="stepsSection.title" :steps="stepsSection.steps" />
  <BrochureProducts
    background-color="bg-ol-primary-10"
    :products="productSection.products"
  />
  <ExpertsCard
    :headshots="expertsSection.headshots"
    :heading="expertsSection.heading"
    :paragraph="expertsSection.paragraph"
  />
  <SlidingText :sliding-paragraphs="slidingParagraphs" />
  <SamsStory :body="samStory.body" :title="samStory.title" />
  <OLTrustpilotReviews />
  <OLFaqs
    heading="Death comes with all sorts of questions. Existential, emotional, logistical. Whatever questions you have, we can stretch apart together."
    :faqs="faqs"
  />
  <SectionCta />
</template>

<script setup>
import BrochureProducts from '@js/components/Brochure/BrochureProducts.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import WillPricing from '@components/Will/WillPricing.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import SlidingText from '@components/Brochure/SlidingText.vue';
import SamsStory from '@components/SamsStory.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import OLFaqs from '@components/OLFaqs.vue';

defineProps({
  utmParams: {
    type: String,
    default: '',
    required: false,
  },

  faqs: {
    type: Array,
    required: true,
  },
});
/** AboveFold hero section data */
const heroSection = {
  header: 'Write a will that’s tailored to you.',
  subHeader: 'How will you make it your own?',
  ctaText: "Let's get started",
  caption: 'Laura, Head of Legal and her late Grandmother, Mamá Inés',
  foldBgColour: 'bg-ol-primary-10',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/willwriting_hero.avif',
      import.meta.url
    ),
    alt: 'Will writing Hero Image',
  },
};

const productSection = {
  products: [
    {
      title: 'Our basic and bespoke will services',
      image: 'penPaper',
      alt: 'Illustration of pen and paper',
    },
    {
      title: 'Getting a fast-tracked will',
      image: 'arrowTarget',
      alt: 'Illustration of arrow striking a target',
    },
    {
      title: 'Writing a mirror will',
      image: 'twoPages',
      alt: 'Illustration of two pages with text on',
    },
    {
      title: 'Our will storage and update service',
      image: 'closedSafe',
      alt: 'Illustration of a closed safe',
    },
  ],
};

const stepsSection = {
  title: 'Write your will in 3 simple steps',
  steps: [
    {
      name: 'Find the right will for you',
      description:
        'Answer a few simple questions, or call our expert team, to determine the type of will you need.',
    },
    {
      name: 'Complete your will',
      description:
        'Create your will with one of our will writers, or via our online platform.',
    },
    {
      name: 'Check and sign your will',
      description:
        'Our legal team review your will then send it to you to sign. You can use our upgrade services to update your will whenever you need to.',
    },
  ],
};

const expertsSection = {
  heading: 'Need a helping hand?',
  paragraph:
    'You can ask our expert team who will support you every step of the way.',
  headshots: ['laura', 'eliza', 'sam', 'dylan', 'katie'],
};

const slidingParagraphs = [
  {
    text: 'When people think of wills, they think\nabout things like wealth, assets,\nproperty,',
  },
  {
    text: 'What secret don’t you want to take\nto your grave?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'If you could relive one memory, what\nwould it be?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'What should everyone wear at your\nfuneral?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'What songs are on the playlist of\nyour life?',
    textColourCss: 'text-ol-secondary-200',
  },
];

const samStory = {
  title: 'A will isn’t for you – you’ll never use it.',
  body: `It is for the people you leave behind. We know the difference a good plan makes and what it's like when there isn't one.\n
In 2016 our founder Sam's Mum died suddenly. It was tough enough, but the frustration of legal fees and mess that came with it was overwhelming.\n
That's why we started Octopus Legacy, so no one else has to go through the same thing.`,
};
</script>
