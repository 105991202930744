<template>
  <div>
    <section
      class="bg-ol-accent-2-10 pt-20 lg:pt-36 pb-10 px-5 xs:px-8 sm:px-7 sm:pb-20 rounded-t-3xl"
    >
      <Flower class="mr-auto lg:mx-auto size-20" />
      <h1
        class="font-bold text-3xl md:text-4xl lg:text-5xl pb-5 md:pb-7 pt-10 lg:text-center"
      >
        {{ heading }}
      </h1>
      <p class="text-lg md:whitespace-pre-wrap lg:text-center">
        {{ paragraph }}
      </p>
    </section>
    <section
      class="relative pb-16 md:pb-2 bg-ol-accent-2-10 rounded-b-3xl px-5 xs:px-8 sm:px-7"
      :style="videoSectionMargin"
    >
      <video
        ref="section"
        :poster="thumbnail"
        :src="video"
        allow="autoplay"
        controls="controls"
        playsinline
        controlsList="nodownload"
        class="mx-auto rounded-3xl w-full max-w-[56rem]"
        :style="videoMargin"
      />
    </section>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useElementSize, useWindowSize } from '@vueuse/core';
import { useTemplateRef } from 'vue';
import Flower from '@components/Icon/Flower.vue';

const props = defineProps({
  thumbnail: {
    type: URL,
    default: new URL(
      '/public/theme/brochure/images/about/video-thumbnail.avif',
      import.meta.url
    ),
  },

  video: {
    type: String,
    default:
      'https://a.storyblok.com/f/309177/x/1bfef5d928/oct_leg_0005_legacy_box_all-contributors_v2_16x9.mp4',
  },
  heading: {
    type: String,
    default: 'Our mission',
  },
  paragraph: {
    type: String,
    default: `Octopus Legacy is a place to plan for death and find support following a loss.
But it’s also a challenge: to create a legacy that connects you to people you love. In life and in death`,
  },
});

const videoSectionHeight = useTemplateRef('section');
const { height } = useElementSize(videoSectionHeight);
const { width } = useWindowSize();

const isSm = computed(() => width.value < 640);

const marginCalculation = computed(() => {
  return isSm.value ? 0 : Math.round(height.value / 2);
});

const videoMargin = computed(() => {
  return `margin-bottom:-${marginCalculation.value}`;
});
const videoSectionMargin = computed(() => {
  return `margin-bottom:${marginCalculation.value}`;
});
</script>

<style scoped></style>
