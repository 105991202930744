<template>
  <div class="row">
    <div class="column">
      <slot name="leftColumn" />
    </div>
    <div class="column">
      <slot name="rightColumn" />
    </div>
  </div>
</template>

<style scoped>
.row {
  @apply flex flex-wrap max-w-content
  flex-col lg:flex-row
  gap-y-5 xs:gap-y-8 lg:gap-x-10 xl:gap-x-12;
}

.column {
  @apply flex flex-col flex-1
  gap-y-5 xs:gap-y-8 lg:gap-y-10 xl:gap-y-11;
}
</style>
