<template>
  <OLHeroSection
    title="We’re here to help you"
    sub-title="Check out our dozens of jargon-free guides"
    data-testid="hero-section"
    border-css="!p-0 bg-[unset]"
    use-new
    skinny-padding
  />
  <CategoriesSection v-if="categories.length" :categories="sortedCategories" />
  <ExpertsCard
    :heading="guideSection.heading"
    :paragraph="guideSection.text"
    :headshots="guideSection.headshots"
  />
  <SectionCta
    :image-bg="false"
    class="py-2 sm:py-3 lg:py-28 xl:py-36 lg:px-16 xl:px-24 2xl:px-32 !mb-0"
  />
  <NewsLetterSignup />
</template>

<script setup>
import NewsLetterSignup from '@components/NewsLetterSignup.vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import CategoriesSection from '@components/Brochure/guides/CategoriesSection.vue';
import { computed, provide } from 'vue';

const props = defineProps({
  articles: {
    type: Object,
    required: true,
  },
  categories: {
    type: Array,
    default: [],
  },
});

const categoryOrder = [
  'Bringing Death to Life',
  'Grief, Loss & Bereavement',
  'Funeral Costs',
  'Funeral Planning',
  'Admin & Legal',
];

const sortedCategories = computed(() =>
  categoryOrder.map((categoryName) => {
    return props.categories.find((cat) => cat.name === categoryName);
  })
);

provide('articles', props.articles);

const guideSection = {
  heading: 'Need a helping hand?',
  text: 'You can ask our expert team who will support you every step of the way.',
  headshots: ['laura', 'eliza', 'sam', 'dylan', 'katie'],
};
</script>

<style scoped></style>
