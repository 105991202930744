<template>
  <section class="how-it-works">
    <div class="flex flex-col gap-y-3">
      <h5 class="how-it-works__header" data-testid="fwig-how-it-works-header">
        How it works
      </h5>
      <p
        class="how-it-works__description"
        data-testid="fwig-how-it-works-description"
      >
        We're bringing planning back to life with this easy to use and fun to
        navigate box.
      </p>
    </div>
    <div
      class="how-it-works__container"
      data-testid="fwig-how-it-works-container"
    >
      <div
        v-for="(step, index) in stepByStep"
        :key="index"
        class="how-it-works__card"
      >
        <!-- <span class="w-40 h-40 border-2 border-ol-dark-ink-200" /> -->
        <b>{{ index + 1 }}. {{ step.step }}</b>
        <p>
          {{ step.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
const stepByStep = [
  {
    step: 'Place your order',
    description:
      'The box can be delivered directly to you, or it can be sent as a gift to someone you love.',
  },
  {
    step: 'Fill the box with your plans ',
    description:
      'Store your will, insurance documents and account information, all in one safe space. This small but mighty act makes life easier for the people you leave behind - creating space for them to focus on what matters.',
  },
  {
    step: 'Share more than just money',
    description:
      'This is your invitation to share something much more valuable. Share photos, memories, advice, wishes, letters and much more.',
  },
];
</script>

<style lang="scss" scoped>
.how-it-works {
  @apply flex
  flex-col
  gap-y-5 lg:gap-y-10
  rounded-2xl
  bg-ol-accent-2-10
  px-5 xs:px-8 lg:px-10 xl:px-36
  py-20 lg:py-28 xl:py-36;
}

.how-it-works__header {
  @apply text-4xl
  text-ol-dark-ink-200
  font-bold
  self-stretch
  lg:text-center;
}

.how-it-works__description {
  @apply sm:text-lg md:text-xl
  lg:text-center
  self-stretch;
}

.how-it-works__container {
  @apply flex
  flex-col
  lg:flex-row
  lg:gap-x-8 xl:gap-x-16
  justify-center
  items-start
  self-center
  max-w-content
  gap-y-5;
}

.how-it-works__card {
  @apply flex
  flex-col
  gap-y-3
  self-stretch
  flex-1;

  > b {
    @apply text-xl md:self-start;
  }
}
</style>
