<template>
  <section class="charities-intro">
    <h3 class="text-3xl lg:text-center">
      Octopus Legacy is here for you if...
    </h3>
    <div
      class="flex flex-col justify-start items-center lg:flex-row gap-x-9 xl:gap-x-14 2xl:gap-12 gap-y-9 max-w-content"
    >
      <div v-for="point in introPoints" class="flex relative pl-4 self-stretch">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 h-full w-[6px] rounded-full bg-ol-pink-200"
        />
        <div>
          <p class="text-base xs:text-lg md:text-xl">
            {{ point }}
          </p>
        </div>
      </div>
    </div>
    <OLButton
      variant="special"
      size="sm"
      class="self-start lg:self-center"
      :link-to="linkTo"
    >
      Work with us
      <ArrowRightIcon class="h-6" />
    </OLButton>
  </section>
</template>

<script setup>
import OLButton from '@components/Button/OLButton.vue';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';

defineProps({
  linkTo: {
    type: String,
    required: true,
  },
});

const introPoints = [
  'You’re struggling to encourage supporters to give a gift in their will',
  'You’re finding it hard to track your pledges',
  'You’re juggling tight budgets with limited resources',
];
</script>

<style lang="scss" scoped>
section.charities-intro {
  @apply bg-[unset]
  flex
  flex-col
  lg:justify-center
  lg:items-center
  gap-8 md:gap-10
  pt-20 lg:pt-28 xl:pt-36
  px-5 xs:px-8 lg:px-28 xl:px-32;

  > h3 {
    @apply text-3xl;
  }
}
</style>
