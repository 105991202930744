import DOMPurify from 'dompurify';

export const vSafeHtml = {
  beforeMount(el, binding) {
    if (binding.value) {
      el.innerHTML = DOMPurify.sanitize(binding.value); // Render Html safely
    }
  },
  updated(el, binding) {
    if (binding.value) {
      el.innerHTML = DOMPurify.sanitize(binding.value);
    }
  },
};
