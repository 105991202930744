<template>
  <div
    class="p-6 flex gap-y-6 flex-col md:flex-row border-2 rounded-2xl justify-between"
  >
    <div
      class="flex flex-col gap-y-2 lg:flex-row lg:flex-wrap md:my-auto md:gap-x-3 flex-1"
    >
      <h4 class="text-xl">
        {{ role.title }}
      </h4>
      <p class="text-lg text-ol-ashes">
        {{ role.location }}
      </p>
    </div>
    <div
      class="flex flex-col sm:flex-row flex-wrap flex-end gap-y-2 gap-x-4 items-center"
    >
      <OLButton size="sm" variant="secondary" @click="findOutMore">
        Find out more
      </OLButton>
      <OLButton size="sm" variant="special" @click="gotToJob(role.providerUrl)">
        Apply
        <Arrow />
      </OLButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';

export default defineComponent({
  components: { Arrow, OLButton },
  props: {
    role: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const gotToJob = (url) => window.open(url);
    const findOutMore = () => window.open(route('jobs.show', props.role.id));
    return { gotToJob, findOutMore };
  },
});
</script>
