<template>
  <section class="bg-ol-cream flex flex-col">
    <div class="terms-content">
      <p>
        These are the terms &amp; conditions applicable when you appoint
        {{ octopusTrusteesName }} to deliver Executor Services (as defined
        below).
      </p>
      <p class="mb-10">
        It is important that you read through these terms and the detailed
        information set out on our website before appointing Octopus Legacy
        Trustees as an executor on your will.
      </p>
      <div id="quest1" class="term-container">
        <h2>1. Definitions and interpretation</h2>
        <div class="content-container">
          <p>In these terms and conditions</p>
          <p>
            1.1. “Executor Services” means
            {{ octopusTrusteesName }} acting as an executor of an estate, an
            administrator of an estate with a will annexed or on intestacy, and
            administering that estate.
          </p>
          <p>
            1.2. “we”, “us”, “our” means
            {{ octopusTrusteesName }}.
          </p>
          <p>
            1.3. Octopus Legacy Trustees is a trading name of
            {{ octopusTrusteesName }}, a company registered in England and Wales
            under company registration number
            {{ octopusTrusteesCompanyNumber }}.
          </p>
        </div>
      </div>
      <div id="quest2" class="term-container">
        <h2>2. Our details and how to contact us</h2>
        <div class="content-container">
          <p>
            2.1. Who we are. {{ octopusTrusteesName }} is a company registered
            in England and Wales under company registration number
            {{ octopusTrusteesCompanyNumber }}. {{ octopusTrusteesName }} is a
            subsidiary of {{ octopusLegalCompanyName }}.
          </p>
          <p>
            2.2. Our registered office address. Our registered office
            {{ octopusTrusteesAddressFull }}.
          </p>
          <p>
            2.3. Our place of business. Our principal place of business is
            {{ octopusTrusteesAddressFull }}.
          </p>
          <p>2.4. How to contact us. You can contact us:</p>
          <ol type="a" class="pl-4">
            <li>
              <p>
                (a)&nbsp;&nbsp;&nbsp;&nbsp;using our website contact form; or
              </p>
            </li>
            <li>
              <p>
                (b)&nbsp;&nbsp;&nbsp;&nbsp;by email, using the email address
                published on our website from time to time.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div id="quest3" class="term-container">
        <h2>3. Fees</h2>
        <div class="content-container">
          <p>
            3.1. No fees are required to be paid before the testator's or
            intestate's passing for the provision of Executor Services.
          </p>
          <p>
            3.2. A fixed fee will be applicable for the Executor Services. This
            overall fee is typically expected to be paid after the grant of
            representation (the document issued by the court confirming our
            power to administer the estate) has been issued. The fee for acting
            as executor will be agreed and fixed before we commence the Executor
            Services.
          </p>
          <p>
            The fixed fee may increase where we are required to handle
            businesses, joint property, real estate, assets located abroad,
            valuations, compiling accounts and forms for HM Revenue &amp;
            Customs, estates, or other related trusts pertinent to the
            administered estate.
          </p>
          <p>
            3.3. While delivering the Executor Services, there might be general
            expenses incurred, related to services provided by third parties,
            that will be payable in addition to our fee. These general expenses
            may include, but are not limited to, solicitors fees, other
            professionals, commercial agents, as well as Stamp Duty.
          </p>
        </div>
      </div>
      <div id="quest4" class="term-container">
        <h2>4. Estate administration</h2>
        <div class="content-container">
          <p>
            4.1. We will usually appoint a solicitor to perform legal work in
            connection with the estate and we reserve the right to appoint a
            solicitor of our choice, and, where appropriate or necessary, to
            appoint such other professionals as we deem fit. All expenses
            related to such appointments shall fall under general expenses
            payable in addition to our fee described in Section 3 above.
          </p>
          <p>
            4.2. We may, in the exercise of any of our discretionary or any
            other powers, act through our representatives.
          </p>
          <p>
            4.3. We may, at our discretion, transfer any property of the estate
            or trust to any person as our nominee (i.e. a person or company
            appointed by us to hold the property in their own name).
          </p>
          <p>
            4.4. In any case where we perform Executor Services jointly with
            other personal representatives, all money, securities, title deeds
            and documents, belonging or relating to the estate shall be under
            our control; and any other personal representative shall have all
            reasonable facilities for verification. The name of
            {{ octopusTrusteesName }} shall be placed first in the registers of
            all registered stocks, shares, securities or property.
          </p>
          <p>
            4.5. We will continue a trade or business or hold shares in a
            company with unlimited liability only with a view to realisation or
            distribution (unless it sees a good reason to the contrary) and on
            the terms that we shall be indemnified against expenses and losses
            from the estate. We shall not be under a duty to hold such shares in
            our own name and us and our co-personal representatives (if any)
            shall have power without any order of the Court to effect any
            disposition or transaction relating to any such sole proprietorship
            or shares which the Court would have had jurisdiction to authorise
            under s57 of the Trustee Act 1925 (as amended or replaced from time
            to time).
          </p>
          <p>
            4.6. We may take insurance on behalf of the estate in respect of any
            risks which we consider may affect the estate assets. The premiums
            for such insurance may be charged against estate income or estate
            capital as we see fit.
          </p>
          <p>
            4.7. We shall be entitled to remuneration in accordance with our
            fees in force at the date of death where we are appointed executor
            by a will or act as administrator under a will or intestacy. We
            agree our fees upfront with you and take our fees free from all
            taxes, from the estate, before we distribute the assets to your
            beneficiaries. General expenses will be charged in addition to the
            fee.
          </p>
          <p>
            4.8. Our fees and expenses will normally be payable out of estate
            capital or income. {{ octopusTrusteesName }} and its co-personal
            representatives (if any) shall have discretion to charge such fees
            and expenses, in whole or in part, against income, or between
            different interests in income or capital.
          </p>
          <p>
            4.9. Where we are appointed executor of a will we will give
            favourable consideration to renouncing our appointment upon request,
            provided we are satisfied that suitable alternative arrangements are
            in place and the interests of the beneficiaries are not compromised
            as a result. No fee would be charged for renouncing our appointment.
          </p>
        </div>
      </div>
      <div id="quest5" class="term-container">
        <h2>5. Additional terms</h2>
        <div class="content-container">
          <p>
            5.1. {{ octopusTrusteesName }} does not provide legal advice on the
            preparation of wills.
          </p>
          <p>
            5.2. We may subcontract any part or parts of the Estate Services and
            we may assign, novate or transfer any part or parts our rights and
            obligations under these terms and conditions to another organisation
            without your consent or any requirement to notify you.
          </p>
          <p>
            5.3. We may alter or vary these terms and conditions at any time
            without notice to you.
          </p>
          <p>
            5.4. We will use the personal information you provide to us to
            supply the Executor Services, as set out in our Privacy Notice and
            Cookies Notice. Our privacy notice can be found
            <a :href="route('privacy')" target="_blank">here</a>.
          </p>
          <p>
            5.5. We will only give your personal information to third parties
            where the law either requires or allows us to do so.
          </p>
          <p>
            5.6. If a court finds part of this contract illegal or
            unenforceable, the rest will continue in force.
          </p>
          <p>
            5.6.1. If a provision of these terms and conditions is determined by
            any court or other competent authority to be unlawful and/or
            unenforceable, the other provisions will continue in effect.
          </p>
          <p>
            5.6.2. If any unlawful and/or unenforceable provision of these terms
            and conditions would be lawful or enforceable if part of it were
            deleted, that part will be deemed to be deleted, and the rest of the
            provision will continue in effect.
          </p>
          <p>5.7. No third party has any rights under this contract.</p>
          <p>
            5.7.1. A contract under these terms and conditions is for our
            benefit and your benefit, and is not intended to benefit or be
            enforceable by any third party.
          </p>
          <p>
            5.7.2. The exercise of the parties' rights under a contract under
            these terms and conditions is not subject to the consent of any
            third party.
          </p>
          <p>
            5.8. These terms and conditions are governed by and construed in
            accordance with English law. Any disputes relating to a contract
            under these terms and conditions will be subject to the exclusive
            jurisdiction of the courts of England.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const octopusLegalCompanyName = window.config.octopus_legal_company_name;
const octopusTrusteesName = window.config.octopus_trustees_name;
const octopusTrusteesCompanyNumber =
  window.config.octopus_trustees_company_number;
const octopusTrusteesAddressFull = window.config.octopus_trustees_address_full;
</script>

<style scoped>
.terms-content {
  @apply py-20 lg:py-28
  px-5 xs:px-8 lg:px-28 xl:px-32 2xl:px-5
  w-full max-w-screen-xl mx-auto;
}

.term-container {
  @apply mb-10 flex flex-col gap-6;
}

.content-container {
  @apply flex flex-col gap-2;
}

h2 {
  @apply text-ol-dark-ink-200 text-2xl lg:text-3xl font-bold;
}

p {
  @apply text-base text-ol-ashes;
}

a {
  @apply text-ol-primary-100 underline;
}

a:hover {
  @apply text-ol-dark-ink-200 no-underline;
}
</style>
