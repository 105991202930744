<template>
  <OLAboveFold
    :header="heroSection.header"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full sm:w-7/12"
    :cta-text="heroSection.ctaText"
    :hero-link="ctaUrl"
    :fold-bg-colour="heroSection.foldBgColour"
    :pre-call-us="FreeWillPricing"
  />
  <BrochureSteps :header="stepsSection.title" :steps="stepsSection.steps" />
  <BrochureProducts
    background-color="bg-ol-primary-10"
    :products="productSection"
    class="mb-2 sm:mb-3"
  />
  <ExpertsCard
    class="!p-0"
    heading="Need a helping hand?"
    paragraph="You can ask our expert team who will support you every step of the way."
    :headshots="['laura', 'eliza', 'sam', 'dylan', 'katie']"
  />
  <BetterValue free-will show-change-will />
  <SlidingText :sliding-paragraphs="slidingText" />
  <SamsStory :title="samsStory.title" :body="samsStory.body" />
  <OLTrustpilotReviews />
  <OLFaqs
    heading="Death comes with all sorts of questions. Existential, emotional, logistical. Whatever questions you have, we can stretch apart together."
    :faqs="faqs"
  />
  <SectionCta />
</template>

<script setup>
import BetterValue from '@components/Brochure/BetterValue.vue';
import BrochureProducts from '@components/Brochure/BrochureProducts.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SlidingText from '@components/Brochure/SlidingText.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import OLFaqs from '@components/OLFaqs.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import SamsStory from '@components/SamsStory.vue';
import FreeWillPricing from '@components/Will/FreeWillPricing.vue';

defineProps({
  ctaUrl: {
    type: String,
    default: 'will.charity-will.start',
  },
  faqs: {
    type: Object,
    required: true,
  },
});

/** AboveFold hero section data */
const heroSection = {
  header: 'Claim your free will',
  subHeader:
    'We’ve partnered with charities from across the UK to make sorting your will quick, easy, and free.',
  ctaText: "Let's get started",
  foldBgColour: 'bg-ol-primary-10',
};

const stepsSection = {
  title: 'Write your will in 3 simple steps',
  steps: [
    {
      name: 'Find the right will for you',
      description:
        'Answer a few simple questions, or call our expert team, to determine the type of will you need.',
    },
    {
      name: 'Complete your will',
      description:
        'Create your will with one of our will writers, or via our online platform.',
    },
    {
      name: 'Check and sign your will',
      description:
        'Our legal team review your will then send it to you to sign. You can use our upgrade services to update your will whenever you need to.',
    },
  ],
};

/** Product section data (if partner exists) */
const productSection = [
  {
    title: 'Our basic and bespoke will services',
    image: 'penPaper',
    alt: 'Illustration of pen and paper',
  },
  {
    title: 'Getting a fast-tracked will',
    image: 'arrowTarget',
    alt: 'Illustration of arrow striking a target',
  },
  {
    title: 'Writing a mirror will',
    image: 'twoPages',
    alt: 'Illustration of two pages with text on',
  },
  {
    title: 'Our will storage and update service',
    image: 'closedSafe',
    alt: 'Illustration of a closed safe',
  },
];

/** Text for the sliding text component */
const slidingText = [
  {
    text: 'When people think of wills, they think about things like wealth, assets, property.',
    class: 'md:w-2/3',
  },
  {
    text: "What secret don't you want to take to your grave?",
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'If you could relive one memory, what would it be?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'What should everyone wear at your funeral?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
  {
    text: 'What songs are on the playlist of your life?',
    textColourCss: 'text-ol-secondary-200',
    class: 'md:w-2/3',
  },
];

/** Text for Sams Story section */
const samsStory = {
  title: 'A will isn’t for you – you’ll never use it.',
  body: "It is for the people you leave behind. We know the difference a good plan makes and what it's like when there isn't one.\n\nIn 2016 our founder Sam's Mum died suddenly. It was tough enough, but the frustration of legal fees and mess that came with it was overwhelming.\n\nThat's why we started Octopus Legacy, so no one else has to go through the same thing.",
};
</script>

<style scoped></style>
