<template>
  <div class="flex gap-2 mt-auto">
    <img class="w-16 h-16 rounded-full" :src="image" :alt="name" />
    <div class="text-sm my-auto">
      <h3 class="font-bold">
        {{ name }}
      </h3>
      <span class="text-ol-ashes">{{ jobTitle }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    image: {
      type: [String, URL],
      default: '',
    },
    jobTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
