<template>
  <section class="above-fold-section">
    <div class="above-fold-behind" :class="foldBgGridRows">
      <span :class="foldBgColour" />
      <span :class="!isLg || image ? foldBgColour : 'bg-ol-dark-ink-200'" />
    </div>
    <div
      class="above-fold-hero"
      :class="[HeroShadowOrBackground, heroMarginBottom]"
    >
      <img v-if="image" :src="image.url" :alt="image.alt" />
      <div class="above-fold-hero__content">
        <div class="above-fold-hero__headers" :class="headerClass">
          <h1 data-testid="ol-hero-header" v-html="header" />
          <h2
            :class="subHeaderClass"
            data-testid="ol-hero-subheader"
            v-html="subHeader"
          />
        </div>
        <div v-if="logos" class="above-fold-hero__logos">
          <img
            v-for="(logo, index) in logos"
            :key="index"
            :src="logo.url"
            :alt="logo.alt"
            data-testid="ol-hero-image"
          />
        </div>
        <div class="above-fold-hero__cta" :class="mobileCtaBottomPadding">
          <!-- Hack: Trustpilot doesn't allow dynamic theme... -->
          <TrustPilotStars v-if="isLg && image" theme="light" />
          <TrustPilotStars v-else />
          <!-- End of hack -->
          <OLButton
            :variant="image ? 'hero' : 'hero-inverted'"
            :link-to="heroLink"
            data-testid="ol-hero-cta"
          >
            {{ ctaText }}
            <Arrow />
          </OLButton>
        </div>
        <div
          v-if="caption"
          class="above-fold-hero__caption"
          data-testid="ol-hero-caption"
        >
          <span>
            <p>{{ caption }}</p>
          </span>
        </div>
      </div>
    </div>
    <component
      :is="preCallUs"
      v-if="preCallUs"
      class="above-fold__pre-call-us"
    />
    <Callback
      dark-mode
      class="above-fold__callback"
      :class="{ 'mb-20': postCallUs }"
    />
    <component
      :is="postCallUs"
      v-if="postCallUs"
      class="above-fold__post-call-us"
    />
  </section>
</template>

<script setup>
import TrustPilotStars from '@components/Brochure/TrustPilotStars.vue';
import OLButton from '@components/Button/OLButton.vue';
import Callback from '@components/Callback.vue';
import Arrow from '@components/Icon/Arrow.vue';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  headerClass: {
    type: String,
    default: null,
  },
  subHeader: {
    type: String,
    required: true,
  },
  subHeaderClass: {
    type: String,
    default: 'w-full',
  },
  ctaText: {
    type: String,
    required: true,
  },
  foldBgColour: {
    type: String,
    required: true,
  },
  image: {
    type: Object,
    default: null,
  },
  caption: {
    type: String,
    default: null,
  },
  heroLink: {
    type: String,
    default: null,
  },
  mobileCtaBottomPadding: {
    type: String,
    default: null,
  },
  logos: {
    type: Array,
    default: null,
  },
  preCallUs: {
    type: Object,
    default: null,
  },
  postCallUs: {
    type: Object,
    default: null,
  },
});

/** Determine the size of the margin to go below the Hero */
const heroMarginBottom = computed(() => (props.preCallUs ? 'mb-20' : 'mb-32'));

/** Determine whether to display a shadow behind the image or the default colour */
const HeroShadowOrBackground = computed(() =>
  props.image ? 'lg:shadow-2xl' : 'bg-ol-dark-ink-200'
);

/** Determine the number of grid rows to use to ensure correct placement on the behind-fold end */
const foldBgGridRows = computed(
  () => 'row-span-2 ' + (props.postCallUs ? 'lg:row-span-5' : 'lg:row-span-3')
);

/** ref to track the window width */
const windowWidth = ref(window.innerWidth);
/** Determine the 'lg' media query in JS (for non-css stuff)  */
const isLg = computed(() => windowWidth.value < 1024);
onMounted(() =>
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  })
);
</script>

<style lang="scss" scoped>
section.above-fold-section {
  display: grid;
  grid-template-rows: repeat(7, auto);

  @apply place-items-center
  bg-ol-cream
  pt-2 sm:pt-3 md:pt-8 lg:pt-10 2xl:pt-16
  md:px-4 lg:px-0;

  > * {
    z-index: 1;
  }

  div.above-fold-behind {
    @apply grid
    grid-rows-3
    col-start-1
    row-start-1 lg:row-start-2
    h-4/5 lg:h-full
    w-full;

    > span {
      @apply block
      col-start-1
      row-span-2
      w-full h-full;
    }

    > span:first-of-type {
      @apply row-start-1 rounded-t-3xl lg:rounded-t-lg
      lg:-skew-y-[8deg];
    }

    > span:last-of-type {
      @apply row-start-2 rounded-b-3xl lg:rounded-b-lg;
    }
  }

  div.above-fold-hero,
  .above-fold__callback {
    @apply w-full lg:w-9/10 xl:w-5/6 2xl:w-8/10 lg:h-fit;
  }

  div.above-fold-hero {
    @apply relative
    col-start-1 row-start-1
    row-span-2;

    &,
    > img {
      @apply rounded-t-3xl lg:rounded-3xl;
    }

    &:has(img) {
      &,
      > div.above-fold-hero__content {
        @apply lg:aspect-2;
      }

      > div.above-fold-hero__content {
        @apply lg:h-full lg:gap-4;
      }
    }

    > img {
      @apply absolute
      top-0 left-0
      w-full lg:h-full
      lg:max-h-full lg:max-w-full
      aspect-[1.8]
      object-cover;

      & + div.above-fold-hero__content {
        > div.above-fold-hero__headers,
        > div.above-fold-hero__cta {
          @apply text-black lg:text-white;

          @media (min-width: 1024px) {
            &:before {
              content: '';
              @apply absolute
              -left-16
              w-full
              h-full
              bg-gray-100;
              filter: blur(10rem);
            }
          }
        }

        > div.above-fold-hero__headers {
          @media (min-width: 1024px) {
            &:before {
              @apply -top-4;
            }
          }
        }

        > div.above-fold-hero__cta {
          @media (min-width: 1024px) {
            &:before {
              @apply top-4;
            }
          }
        }

        > div.above-fold-hero__logos {
          &:before {
            content: '';
            @apply absolute
            top-0 left-0 lg:-right-12
            h-1/2 lg:h-full
            w-1/2
            bg-gray-100;
            filter: blur(10rem);
          }
        }
      }
    }

    > div.above-fold-hero__content {
      grid-template-rows: repeat(2, auto);

      @apply relative
      overflow-hidden
      grid
      grid-cols-1 lg:grid-cols-2
      gap-12
      w-full
      lg:p-12 xl:p-16 2xl:p-20
      rounded-t-3xl lg:rounded-3xl;

      > div.above-fold-hero__headers,
      > div.above-fold-hero__cta,
      > div.above-fold-hero__logos,
      > div.above-fold-hero__caption {
        @apply relative;
        > * {
          @apply relative;
          z-index: 1;
        }
      }

      > div.above-fold-hero__headers,
      > div.above-fold-hero__cta {
        @apply px-5 xs:px-8 lg:px-0;
      }

      > div.above-fold-hero__headers {
        @apply col-start-1
        row-start-2 lg:row-start-1
        lg:col-end-3
        text-white
        max-w-[90vw];

        > h1 {
          @apply font-bold
          text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl
          mb-5 sm:mb-6 lg:mb-8
          whitespace-pre-wrap;
        }

        > h2 {
          @apply xl:text-lg 2xl:text-xl
          leading-normal;
        }
      }

      > div.above-fold-hero__logos {
        @apply row-start-1
        col-start-1 lg:col-start-2
        flex
        flex-col
        items-start lg:items-end
        gap-2 lg:gap-5
        pl-5 pt-5 lg:p-0;

        > img {
          @apply h-1/6 lg:h-1/4
          max-h-12
          shrink-0
          grow-0;
        }
      }

      > div.above-fold-hero__cta {
        @apply flex
        flex-col
        gap-8 lg:gap-6 xl:gap-8
        col-start-1
        row-start-3 lg:row-start-2
        place-content-end;
      }

      > div.above-fold-hero__caption {
        @apply w-full lg:w-fit
        aspect-[1.8] lg:aspect-[unset]
        row-start-1 lg:row-start-2
        col-start-1 lg:col-start-2
        p-4 sm:p-6 lg:p-0
        place-self-end
        flex
        items-end justify-end
        overflow-hidden lg:overflow-visible;

        > span {
          @apply relative;

          > p {
            @apply relative
            text-right text-white
            text-2xs xs:text-xs sm:text-sm lg:text-xs;
            z-index: 1;
          }

          &:before {
            content: '';
            filter: blur(2rem);
            z-index: 0;

            @apply absolute
            top-0 left-0
            w-full h-full
            bg-gray-100;
          }
        }
      }
    }
  }

  .above-fold__pre-call-us {
    @apply mb-20;
  }

  div.above-fold-hero,
  .above-fold__callback {
    @apply max-w-[60rem] xl:max-w-content;
  }

  .above-fold__pre-call-us,
  .above-fold__callback,
  .above-fold__post-call-us {
    @apply col-start-1;
  }

  .above-fold__callback,
  .above-fold__post-call-us {
    @apply row-span-2;
  }

  .above-fold__pre-call-us,
  .above-fold__post-call-us {
    @apply w-full p-0;
  }

  .above-fold__pre-call-us {
    @apply row-start-3;
  }

  .above-fold__callback {
    @apply row-start-4
    p-0;
  }

  .above-fold__post-call-us {
    @apply row-start-6;
  }
}
</style>
