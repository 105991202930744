<template>
  <section class="bg-ol-cream flex flex-col">
    <article class="cookies-content">
      <h2>How we use cookies</h2>
      <p>
        Our website uses cookies and similar tracking technologies. A cookie is
        a small file of letters and numbers downloaded onto a device when the
        user accesses certain websites. Cookies allow a website to recognise a
        user’s machine.
      </p>
      <p>
        We also share information about your use of our site with our social
        media, advertising and analytics partners who may combine it with other
        information that you have provided to them or that they have collected
        from your use of their services.
      </p>
      <p class="mb-20">
        For more information about cookies, how they work, how to delete them
        from your browser, and what choices you can make through your own
        browser settings see
        <a href="https://www.allaboutcookies.org" target="_blank">
          www.allaboutcookies.org.
        </a>
      </p>

      <h2>Types of cookies</h2>
      <p class="mb-6">Our website uses the following types of cookies:</p>

      <h3>Necessary cookies:</h3>
      <p>
        These cookies are essential in order to enable you to move around the
        website and use its features, such as accessing secure areas of the
        website. Without these cookies websites cannot function properly. These
        cookies don’t collect information that identifies a visitor.
      </p>

      <h3>Performance cookies:</h3>
      <p>
        These cookies collect information about how visitors use a website, for
        instance which pages visitors go to most often, and if they get error
        messages from web pages. These cookies do not collect information that
        identifies a visitor. They are used to improve how a website works.
      </p>

      <h3>Functionality cookies:</h3>
      <p>
        These cookies allow the website to remember choices you make (such as
        your username, language or the region you are in) and provide enhanced,
        more personal features. For instance, a website may be able to provide
        you with local weather reports or traffic news by storing in a cookie
        the region in which you are currently located. These cookies can also be
        used to remember changes you have made to text size, fonts and other
        parts of web pages that you can customise. They may also be used to
        provide services you have asked for such as watching a video or
        commenting on a blog. The information these cookies collect may be
        anonymised and they cannot track your browsing activity on other
        websites.
      </p>
    </article>
  </section>
</template>
<style lang="scss" scoped>
.cookies-content {
  @apply py-20 lg:py-28
  px-5 xs:px-8 lg:px-28 xl:px-32 2xl:px-5
  w-full max-w-screen-xl mx-auto;

  & > h2 {
    @apply text-ol-dark-ink-200 text-2xl lg:text-3xl font-bold mb-6;
  }

  & > h3 {
    @apply text-xl text-ol-primary-100 font-bold mb-3;

    & + p {
      @apply mb-6;
    }
  }

  & > p {
    @apply text-base text-ol-ashes;
  }
}

a {
  @apply text-ol-primary-100 underline;
}

a:hover {
  @apply text-ol-dark-ink-200 no-underline;
}
</style>
