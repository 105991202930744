<template>
  <section class="form-container">
    <form class="signup-form max-w-content mx-auto" @submit.prevent="submit">
      <div class="flex flex-col gap-y-5 flex-1">
        <h3 class="signup-form__title">Sign up to our newsletter</h3>
        <p class="signup-form__content">
          Expect inspiring stories from people navigating life, death and
          everything in-between.
        </p>
      </div>
      <div class="flex flex-col gap-5 flex-1 w-full md:w-auto">
        <div
          class="relative signup-form__input-container"
          :class="{ 'signup-form__input-container-focused': isFocused }"
        >
          <label for="email" class="hidden">Email Address</label>
          <input
            id="email"
            ref="inputRef"
            v-model="emailAddress"
            class="signup-form__input"
            type="email"
            name="signupEmailInput"
            placeholder="Email Address"
            required
            @focus="handleFocus"
            @blur="handleBlur"
          />
          <button
            :disabled="submitting"
            type="submit"
            aria-label="sign up to newsletter"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="15.5"
                fill="#160535"
              />
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="15.5"
                stroke="#160535"
              />
              <path
                d="M17 11L22 16M22 16L17 21M22 16H10"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <transition name="fade">
          <p v-show="success" class="text-base">Thank you for subscribing!</p>
        </transition>
        <transition name="fade">
          <p v-show="!!errorMessage" class="text-base">
            {{ errorMessage }}
          </p>
        </transition>

        <p class="text-2xs">
          By providing your email address, you consent to your personal data
          being held in line with our
          <a :href="route('privacy')">Privacy Notice.</a>
        </p>
      </div>
    </form>
  </section>
</template>
<script setup>
import { ref } from 'vue';
import http from '@will/api/http.js';

const emailAddress = ref('');

const inputRef = ref(null);
const isFocused = ref(false);

const handleFocus = () => {
  isFocused.value = true;
};

const handleBlur = () => {
  isFocused.value = false;
};

const success = ref(false);
const errorMessage = ref('');
const submitting = ref(false);

const submit = async () => {
  success.value = false;
  errorMessage.value = '';

  try {
    submitting.value = true;
    const response = await http.post(
      window.route('marketing.newsletter-subscribe'),
      {
        email: emailAddress.value,
      }
    );
    success.value = response.status === 200;
  } catch (e) {
    if (e.type === 'validation') {
      errorMessage.value = e.fields.email[0];
    } else {
      errorMessage.value = 'Unable to subscribe to newsletter';
    }
  }
  submitting.value = false;
};
</script>

<style lang="scss" scoped>
.form-container {
  @apply text-ol-dark-ink-200
  rounded-3xl bg-ol-accent-2-10
  mb-0
  py-20 lg:py-28
  px-5 xs:px-8 sm:px-7 md:px-16 lg:px-24 xl:px-32;
}

.signup-form {
  @apply flex flex-col justify-center items-start lg:flex-row lg:items-center self-stretch
  gap-y-10
  lg:gap-x-10  xl:gap-x-30 2xl:gap-x-40;

  &__title {
    @apply text-2xl xs:text-3xl xl:text-4xl;
  }

  &__content {
    @apply text-base xs:text-lg xl:text-xl;
  }

  &__input-container {
    @apply flex flex-row items-center justify-between
    rounded-full border-2
    border-ol-dark-ink-200
    px-6 xs:px-7
    py-2;

    &-focused {
      @apply border-blue-100 border-2;
    }
  }

  &__input {
    @apply bg-ol-accent-2-10 text-base placeholder:text-ol-dark-ink-200
    border-0 focus:outline-none focus:border-0 focus:ring-0 flex-grow;
  }
}
</style>
