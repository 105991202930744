<template>
  <LegalLayout :title="title" :buttons="buttons">
    <template #content>
      <TrusteeTermsContent />
    </template>
  </LegalLayout>
</template>

<script setup>
import LegalLayout from '@js/domains/brochure/layout/LegalLayout.vue';
import TrusteeTermsContent from '@js/domains/brochure/components/TrusteeTermsContent.vue';

const title = 'Professional Executor Services';

const buttons = [
  {
    name: 'Terms & Conditions',
    href: 'terms',
  },
  {
    name: 'Privacy Notice',
    href: 'privacy',
  },
  {
    name: 'Cookies notice',
    href: 'cookies',
  },
];
</script>

<style scoped></style>
