<template>
  <div class="flex flex-col">
    <OLHeroSection
      title="Work at Octopus Legacy"
      sub-title="We’re tackling some of the biggest taboos surrounding death. Together, we can help to make some of the toughest times in people’s lives easier."
      sub-header-class="max-w-[38rem]"
      data-testid="hero-section"
      border-css="!p-0 bg-[unset]"
      use-new
      skinny-padding
    >
      <template #body>
        <OLButton
          variant="special"
          size="sm"
          link-to="#openRoles"
          class="mt-9 sm:mt-10"
        >
          View open roles
          <ArrowDownIcon class="h-6" />
        </OLButton>
      </template>
    </OLHeroSection>

    <SamsStory :title="samStorySection.title" :body="samStorySection.body" />
    <!-- <OLTimeLineSection /> -->
    <FromOurTeam :from-our-team="fromOurTeam" />
    <OLIconCards :header="perks.header" :cards="perks.benefits" />
    <CoreValues class="mb-2 lg:mb-3" />
    <BCorp class="px-5 py-20 lg:py-28 xl:py-36" />
    <ActiveJobs
      id="openRoles"
      class="px-5 xs:px-7 xl:py-28 lg:px-16 xl:px-24 2xl:px-32 py-20 md:py-28 2xl:py-36"
      :jobs="jobs"
    />
    <SectionCta />
  </div>
</template>

<script setup>
import ActiveJobs from '@js/domains/brochure/components/careers/ActiveJobs.vue';
import BCorp from '@components/Brochure/BCorp.vue';
import CoreValues from '@components/Brochure/CoreValues.vue';
import FromOurTeam from '@js/domains/brochure/components/careers/FromOurTeam.vue';
import OLButton from '@components/Button/OLButton.vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import OLIconCards from '@components/Brochure/OLIconCards.vue';
import SamsStory from '@components/SamsStory.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import { ref } from 'vue';
import {
  ArrowDownIcon,
  BanknotesIcon,
  BoltIcon,
  CakeIcon,
  HandRaisedIcon,
  LifebuoyIcon,
  RocketLaunchIcon,
  SparklesIcon,
  TicketIcon,
  TrophyIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline';

defineProps({
  jobs: {
    type: Array,
    default: () => [],
  },
});

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
    });
  });
});

const fromOurTeam = ref({
  heading: 'From our team',
  paragraph: `From weekly all-hands meetings to quarterly planning sessions, you can share your ideas, collaborate with other teams and help us change the way the world deals with death.`,
  image1: new URL('/public/theme/ol/careers1.jpg', import.meta.url),
  imageText1: 'Octopus legacy all hands meeting',
  video: 'https://a.storyblok.com/f/309177/x/cdf7e04203/sports-day.mp4',
  image2: new URL('/public/theme/ol/careers3.jpg', import.meta.url),
  imageText2: 'Octopus legacy founder Sam',
  testimonials: [
    {
      title: 'I get to help people. Every day!',
      review: `When I talk about Wills, Lasting Powers of Attorney, Inheritance Tax or anything else, I help people to focus on what really matters: their loved ones and their legacy. You don't get that everywhere.`,
      name: 'Rashawn',
      jobTitle: 'Estate Planner',
      image: new URL(
        '/public/theme/img/headshots/rashawn.jpg',
        import.meta.url
      ),
    },
    {
      title: 'The company culture is fantastic',
      review: `The fast-paced, high-growth environment keeps me constantly challenged and engaged. I love the collaborative spirit among team members which makes every project a rewarding experience. `,
      name: 'Imogen',
      jobTitle: 'Senior Product Manager',
      image: new URL('/public/theme/img/headshots/imogen.jpg', import.meta.url),
    },
    {
      title:
        'We are creating a leading probate service, designed to be more compassionate and accessible for families.',
      review: `Octopus Legacy has a unique opportunity to initiate and nurture meaningful conversations, breaking down barriers surrounding death and complex processes, to drive change for the benefit of grieving families during a difficult time.`,
      name: 'June',
      jobTitle: 'Senior Probate Solicitor',
      image: new URL('/public/theme/img/headshots/june.jpg', import.meta.url),
    },
  ],
});

const samStorySection = ref({
  title: 'Our job is to bring death to life.',
  body: 'Born after Sam’s mum died in a car accident, we know the difference a good plan makes, and what it’s like when there isn’t one. \n\nWe see a world where you work out your personal meaning of legacy by talking about death with people you love. We’re here to make that happen.',
});
const perks = {
  header: 'Perks & Benefits',
  benefits: [
    {
      title: 'Flexible Working',
      icon: RocketLaunchIcon,
      paragraph:
        'Hybrid working; 3 days in the office (London Bridge). Flexible holiday policy and work from anywhere four weeks a year.',
    },
    {
      title: 'Share Incentive Scheme (SIP)',
      icon: BanknotesIcon,
      paragraph:
        'Join the Octopus Share Incentive Plan and invest in the company. ',
    },
    {
      title: 'Health and Life Insurance',
      icon: LifebuoyIcon,
      paragraph:
        'We offer Vitality Health & Life Insurance.  In addition, you get access to a free will and LPA.',
    },
    {
      title: 'Cycle to work scheme',
      icon: BoltIcon,
      paragraph:
        'Enjoy our cycle to work scheme and access to the Electric Vehicle Sacrifice Scheme.',
    },
    {
      title: 'Mat & Pat Leave',
      icon: UsersIcon,
      paragraph:
        'We offer enhanced parental leave, providing extended time off to support new parents.',
    },
    {
      title: 'Charity matching',
      icon: HandRaisedIcon,
      paragraph:
        'We match charitable donations up to £500 and support volunteer days throughout the year.',
    },
    {
      title: 'Octopus Springboard',
      icon: TrophyIcon,
      paragraph:
        'Become a fully-fledged entrepreneur with cash funding and time off to start your own business.',
    },
    {
      title: 'Birthdays off',
      icon: CakeIcon,
      paragraph:
        'For a business that deals with death, we’re adamant about celebrating life.',
    },
    {
      title: 'Events and socials',
      icon: TicketIcon,
      paragraph:
        'Company off-site, legendary team socials, free breakfast, drinks and snacks.',
    },
    {
      title: 'Dog-friendly office',
      icon: SparklesIcon,
      paragraph:
        'We are dog-friendly! Feel free to bring in your (well-behaved) floofer.',
    },
  ],
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
