<template>
  <section class="additional-services">
    <div class="additional-services__first">
      <h5
        class="additional-services__header"
        data-testid="additional-services-header"
      >
        Additional services and costs
      </h5>
      <p
        class="additional-services__description"
        data-testid="additional-services-description"
      >
        We're here to support you throughout this process - not just with
        probate. We offer the following additional services, directly or through
        our trusted partners. Any costs will be quoted separately.
      </p>
    </div>
    <div
      class="additional-services__container"
      data-testid="additional-services-container"
    >
      <div
        v-for="(service, index) in additionalServices"
        :key="index"
        class="additional-services__card"
      >
        {{ service }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const additionalServices = [
      'Insurance for executors and beneficiaries',
      'Will, beneficiary and asset searches',
      'Notices to creditors',
      'House clearance services',
      'Property management services',
      'Organising the sale or transfer of properties',
    ];

    return {
      additionalServices,
    };
  },
};
</script>

<style lang="scss" scoped>
.additional-services {
  @apply flex
  flex-col lg:flex-row
  lg:justify-center
  gap-y-10 lg:gap-y-0 lg:gap-x-[5%]
  rounded-3xl
  bg-ol-accent-2-10
  px-5 md:px-10
  py-20 md:py-24 lg:py-36
  mt-2 md:mt-3;
}

.additional-services__first {
  @apply flex flex-col gap-y-3 md:gap-y-7 lg:max-w-xl;
}

.additional-services__header {
  @apply text-4xl text-ol-dark-ink-200;
}

.additional-services__description {
  @apply text-lg md:text-xl;
}

.additional-services__container {
  @apply flex flex-wrap w-full gap-y-3 md:gap-5 lg:min-w-[32rem] lg:max-w-xl;
}

.additional-services__card {
  @apply w-full border-2 rounded-[0.75rem] p-5 md:max-w-[calc(50%-1rem)] md:text-xl;
}
</style>
