<template>
  <section
    class="bg-ol-secondary-20 flex flex-col justify-center items-center gap-y-12 xl:gap-y-15 py-20 lg:py-28 xl:py-36 px-5 xl:px-64 mt-2 sm:mt-3 rounded-2xl"
  >
    <h1 class="text-4xl md:text-4xl xl:text-5xl font-bold text-center mb-7">
      What our customers are saying
    </h1>

    <TwoColumnGrid class="max-w-[1003px]">
      <template #leftColumn>
        <img
          class="rounded-ol-card object-cover flex-grow mb-4 md:mb-0"
          :src="productImages[0].src"
          :alt="productImages[0].title"
        />

        <ProductReviewCard
          class="mb-4 md:mb-0"
          :author="reviews[0].author"
          :paragraph="reviews[0].paragraph"
          :title="reviews[0].title"
        />

        <img
          class="rounded-ol-card object-cover flex-grow mb-4 md:mb-0"
          :src="productImages[2].src"
          :alt="productImages[2].title"
        />
      </template>
      <template #rightColumn>
        <ProductReviewCard
          class="mb-4 md:mb-0"
          :author="reviews[1].author"
          :paragraph="reviews[1].paragraph"
          :title="reviews[1].title"
        />

        <img
          class="rounded-ol-card object-cover mb-4 md:mb-0"
          :src="productImages[1].src"
          :alt="productImages[1].title"
        />

        <ProductReviewCard
          class="mb-4 md:mb-0"
          :author="reviews[2].author"
          :paragraph="reviews[2].paragraph"
          :title="reviews[2].title"
        />
      </template>
    </TwoColumnGrid>
  </section>
</template>

<script setup>
import TwoColumnGrid from '@components/Layout/TwoColumnGrid.vue';
import ProductReviewCard from '@components/Card/ProductReviewCard.vue';

const reviews = [
  {
    title: 'I wish my mum had prepared a legacy box before she passed away',
    paragraph: `
    We chose the wrong music for her funeral because we only found the music she
    wanted 2 months later, hidden in a pile of documents. I have no recordings
    of her voice and no words of advice or wisdom from her for major life events.
    I would have cherished having those when I gave birth to my children.
    That’s what I miss the most, even now, 10 years later.
    `,
    author: 'Fabienne',
  },
  {
    title: 'The legacy box is peace of mind wrapped in a bow',
    paragraph: `
    It's a simple, step-by-step guide that takes the guesswork out of what
    comes next. From legal and financial support to heartfelt messages,
    it ensures my family has everything they need after I die —practical help,
    a source of emotional comfort, and a lasting reminder of the love and
    memories we've shared. I like to plan in life, and in death it's no different.
    `,
    author: 'Julie',
  },
  {
    title: 'A way to spark meaningful conversations with loved ones',
    paragraph: `
    I wasn’t sure what to expect when my daughter gave me my beautiful Legacy Box,
    but as we looked through it, I realised how much it helped us reflect on
    my legacy. From favourite memories, music, and photos to leaving a message
    for loved ones, it offered meaningful ways to prepare.
    The thoughtful questions and guidance made it easier to address important
    matters that aren’t always easy to broach with loved ones.
    `,
    author: 'Linda',
  },
];

const productImages = [
  {
    title: 'Legacy Box Unboxed',
    src: new URL(
      '/public/theme/img/fwig/product-cards-with-notes.avif',
      import.meta.url
    ),
  },
  {
    title: 'Legacy Box',
    src: new URL('/public/theme/img/fwig/dictaphone.avif', import.meta.url),
  },
  {
    title: 'Legacy Card',
    src: new URL('/public/theme/img/fwig/product-cards.avif', import.meta.url),
  },
];
</script>

<style scoped></style>
