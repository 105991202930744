<template>
  <LegalLayout :title="title" :buttons="buttons">
    <template #content>
      <CookiesNoticeContent />
    </template>
  </LegalLayout>
</template>

<script setup>
import CookiesNoticeContent from '@js/domains/brochure/components/CookiesNoticeContent.vue';
import LegalLayout from '@js/domains/brochure/layout/LegalLayout.vue';

const title = 'Cookie Notice';

const buttons = [
  {
    name: 'Terms & Conditions',
    href: 'terms',
  },
  {
    name: 'Privacy Notice',
    href: 'privacy',
  },
];
</script>
