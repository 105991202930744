<template>
  <div class="flex flex-col">
    <h2 class="text-2xl xl:text-3xl text-ol-dark-ink-200 mb-8 sm:mb-12">
      {{ category.name }}
    </h2>
    <div class="flex flex-col justify-between gap-5">
      <OLButton
        v-for="entry in articles[category.value]"
        text-button
        variant="secondary"
        :link-to="entry.fullSlug"
        class="pb-5 !text-base border-b border-gray-50 !block"
      >
        <div class="flex justify-between items-start">
          <span class="text-wrap text-base"> {{ entry.content.title }} </span>
          <span class="mt-1"><Arrow /></span>
        </div>
      </OLButton>
    </div>
    <div class="mt-auto">
      <OLButton
        variant="special"
        class="mt-14"
        size="sm"
        :link-to="articlesRoute(category.value)"
      >
        View all {{ category.name }} articles
      </OLButton>
    </div>
  </div>
</template>
<script setup>
import Arrow from '@components/Icon/Arrow.vue';
import OLButton from '@components/Button/OLButton.vue';
import { inject } from 'vue';

defineProps({
  category: {
    type: Object,
    required: true,
  },
});

const articles = inject('articles');

const articlesRoute = (category) => route('advice.category', { category });
</script>
<style scoped></style>
