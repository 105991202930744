<template>
  <section :class="`section-hero-new ${borderCss}`" data-testid="section-hero">
    <div class="section-hero__container">
      <img v-if="image" :src="image" alt="hero-image" />
      <div :class="heroContentClass">
        <h1
          v-if="title"
          class="section-hero__header"
          :class="headerClass"
          data-testid="section-hero-title"
        >
          {{ title }}
        </h1>
        <h2
          v-if="subTitle"
          class="section-hero__sub-header"
          :class="subHeaderClass"
          data-testid="section-hero-subtitle"
        >
          {{ subTitle }}
        </h2>
        <slot name="body" />
        <TrustPilotStars
          v-if="showTrustpilot"
          class="sm:mr-auto lg:mx-auto"
          theme="light"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import TrustPilotStars from '@components/Brochure/TrustPilotStars.vue';
import { computed } from 'vue';

const props = defineProps({
  image: {
    type: String,
    default: '/theme/brochure/images/hero_images/hero_bg.avif',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  headerClass: {
    type: String,
    default: null,
  },
  subTitle: {
    type: String,
    required: false,
    default: '',
  },
  subHeaderClass: {
    type: String,
    default: null,
  },
  showTrustpilot: {
    type: Boolean,
    default: false,
    required: false,
  },
  borderCss: {
    type: String,
    required: false,
    default: '',
  },
  skinnyPadding: {
    type: Boolean,
    default: false,
  },
});

/** Whether to use skinny padding or not */
const heroContentClass = computed(
  () => `section-hero__content${props.skinnyPadding ? '-skinny' : ''}`
);
</script>

<style lang="scss" scoped>
.hero--image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-hero {
  @apply relative overflow-hidden;
}

.section-hero-new {
  @apply flex
  flex-col
  p-0.5
  mt-2 sm:mt-3
  rounded-3xl;
}

.section-hero__container {
  // 1.5rem - 0.125rem (rounded-3xl - p-0.5) to get inner border-radius
  @apply relative w-full
  rounded-[1.375rem]
  bg-clip-content
  overflow-hidden;

  > img {
    @apply absolute top-1/2 left-1/2 min-h-full min-w-[101%] object-cover;
    transform: translate(-50.05%, -50%);
  }

  .section-hero__content,
  .section-hero__content-skinny {
    @apply flex
    flex-col
    px-5 xs:px-8 sm:px-7 lg:px-10;

    h1.section-hero__header {
      @apply font-ol-roobert
      font-bold;
    }

    h2.section-hero__sub-header {
      @apply font-ol-roobert
      sm:text-xl md:text-lg lg:text-xl
      lg:text-center;
    }
  }

  .section-hero__content {
    @apply lg:justify-center lg:items-center
    py-20 md:py-28 lg:py-36;

    h1.section-hero__header {
      @apply text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl
      lg:text-center
      mb-5 md:mb-8;
    }

    h2.section-hero__sub-header {
      @apply lg:text-center
      text-lg;
    }
  }

  .section-hero__content-skinny {
    @apply justify-center sm:items-center
    py-10 xs:py-14 lg:py-20 xl:py-24;

    h1.section-hero__header {
      @apply text-2xl xs:text-3xl md:text-4xl lg:text-5xl xl:text-6xl
      mb-5 2xl:mb-8;
    }

    h2.section-hero__sub-header {
      @apply xs:text-lg;
    }
  }
}
</style>
<style lang="scss">
.section-hero__container * {
  @apply z-0;
}
</style>
