<template>
  <LegalLayout
    :title="title"
    :buttons="buttons"
    :content-table="contentTable"
    :content="content"
  />
</template>

<script setup>
import LegalLayout from '@js/domains/brochure/layout/LegalLayout.vue';

const octopusLegalCompanyName = window.config.octopus_legal_company_name;
const octopusLegalContactEmail = window.config.legal_email;
const octopusLegalCompanyFullAddress =
  window.config.company_octopus_legacy_address;
const octopusLegalCompanyNumber = window.config.legal_company_number;

const title = 'Octopus Legal Privacy Notice';

const props = defineProps({
  updatedAt: {
    type: String,
    required: true,
  },
});

const buttons = [
  {
    name: 'Terms & Conditions',
    href: 'terms',
  },
  {
    name: 'Privacy Notice',
    href: 'privacy',
  },
];

const contentTable = [
  { name: 'Who we are', href: 'quest1' },
  { name: 'Personal data we collect', href: 'quest2' },
  { name: 'How we collect personal data', href: 'quest3' },
  { name: 'How and why we use personal data', href: 'quest4' },
  { name: 'Marketing', href: 'quest5' },
  { name: 'Who we share personal data with', href: 'quest6' },
  { name: 'How long we keep personal data', href: 'quest7' },
  { name: 'Your rights', href: 'quest8' },
  { name: 'Keeping personal data', href: 'quest9' },
  { name: 'Complaints', href: 'quest10' },
  { name: 'How to contact us', href: 'quest11' },
  { name: 'Changes to this privacy policy', href: 'quest12' },
];

const content = `
<div>
  <article>
    <h2 class="!mt-0">The purpose of this notice</h2>
    <p>We are committed to protecting your privacy and safeguarding your personal data. Our use of your
      personal data is
      subject to the UK General Data Protection Regulation, Data Protection Act 2018, and other UK privacy
      laws (together
      <strong>Data Protection Legislation</strong>).</p>
    <p>In this privacy notice we explain how we will process your personal information obtained through your
      use of the website <a href="https://octopuslegacy.com/probate">https://octopuslegacy.com/probate</a>,
      our services, and through direct interactions with you.</p>
    <p>It is important that you read this privacy notice, together with any other privacy notice we may
      provide on specific occasions, so that you are fully aware of how and why we are using your data,
      and what data protection rights you have.</p>
    <h2>What does this notice cover?</h2>
    <p>You can either scroll down to read this notice in its entirety (which we recommend), or click on one
      of the links below to go straight to the section you are interested in.
    </p>
    <div id="quest1">
      <h2>1. Who we are</h2>
      <div>
        <p>When we say <strong>we</strong>, <strong>us</strong> or <strong>our</strong> in this privacy notice, we
          mean ${octopusLegalCompanyName}, a company incorporated and registered in
          England and Wales with
          company number ${octopusLegalCompanyNumber} and whose registered
          office is at ${octopusLegalCompanyFullAddress}.</p>
        <p>For the purposes of the Data Protection Legislation, we are the <strong>controller</strong> of your
          personal data. This means that we are responsible for deciding how we hold and use personal
          information about you.</p>
        <p>If you have any questions about this policy or the personal data we hold about you, please
          contact our Data Protection Lead, Laura Puentes Gantiva at <a
            href="mailto:laura.puentes@octopus.legal">laura.puentes@octopus.legal</a>.
        </p>
      </div>
    </div>
    <div id="quest2"><h2>2. Personal data we collect</h2>
      <div class="flex flex-col gap-y-6">
        <p><strong>Personal data</strong> means information which relates to an identified or an identifiable
          individual.</p>
        <table>
          <tr>
            <th>Types of personal data we may collect</th>
            <th>Examples</th>
          </tr>
          <tr>
            <td>Identity data</td>
            <td>Title; first name; last name; username.</td>
          </tr>
          <tr>
            <td>Contact data</td>
            <td>Address; email; telephone number.</td>
          </tr>
          <tr>
            <td>Profile data</td>
            <td>Interests; preferences; feedback and survey responses; posts and materials uploaded
              onto your website.
            </td>
          </tr>
          <tr>
            <td>Professional data</td>
            <td>Job title; name of business or organisation; professional credentials; professional
              contact details.
            </td>
          </tr>
          <tr>
            <td>Usage data</td>
            <td>Services you signed up to; details of free materials downloaded from our website;
              services purchased and prices paid.
            </td>
          </tr>
          <tr>
            <td>Communication data</td>
            <td>Details of enquiries submitted via our website or emailed to us, details of our
              communication with you.
            </td>
          </tr>
          <tr>
            <td>Contract data</td>
            <td>Details of our contracts with you.</td>
          </tr>
          <tr>
            <td>Probate Service data</td>
            <td>Information which you provide us with in connection with our probate service,
              including your title, name, date of birth, address, contact details, and information
              about the deceased, including their marital status, details about their family
              members and their estate (including assets and liabilities), and where there is a
              will, details of their chosen guardians, details of their chosen executors, and
              details of their beneficiaries, as well as their funeral wishes.
            </td>
          </tr>
          <tr>
            <td>Technical data</td>
            <td>Internet protocol (IP) address, your login data, browser type and version, time zone
              setting and location, browser plug-in types and versions, operating system and
              platform and other technology on the devices you use to access this website.
            </td>
          </tr>
        </table>
        <p>We do not provide services directly to children below 16 or proactively collect their
          personal data. We do however collect the personal data of children indirectly through adults
          using our services (for example, if a parent is the deceased person for whom our probate
          service is required). Our Probate Service can only be used by individuals who are at least
          18 years old.</p>
        <p>Before you disclose to us the personal information of another person you must ensure that you
          have a lawful basis to do so. For information on when and how you can lawfully disclose
          personal data, please see the Information Commissioner’s Office <a
            href="https://ico.org.uk/media/for-organisations/guide-to-the-general-data-protection-regulation-gdpr-1-0.pdf"
            target="_blank">Guide to the General Data Protection Regulation</a>.</p>
        <p>We may also collect, use and share anonymised, aggregated data such as statistical or
          demographic data for any purpose. Anonymised data may be derived from your personal data but
          is not considered personal information by law, as this information does not directly or
          indirectly reveal your identity. For example, we may aggregate information on how you use
          our website to calculate the percentage of users accessing a specific website feature.</p>
      </div>
    </div>
    <div id="quest3"><h2>3. How we collect personal data</h2>
      <div class="flex flex-col gap-y-6">
        <p>We collect most of this information from you directly. However, we may also collect
          information from other sources.</p>
        <table>
          <tr>
            <th>Type of source</th>
            <th>Examples</th>
          </tr>
          <tr>
            <td>Your use of our website and services</td>
            <td>when you sign up to or use our services; sign up to our mailing list; submit an
              online enquiry; subscribe to our blogs, complete a contact form; complete a survey;
              submit feedback.
            </td>
          </tr>
          <tr>
            <td>Direct interactions with you</td>
            <td>when you first contact us (e.g., by phone or email); when you register interest in
              our services; when you give us your business card.
            </td>
          </tr>
          <tr>
            <td>From publicly accessible sources</td>
            <td>your website; your profiles on social media platforms (e.g., LinkedIn, Facebook,
              Twitter); professional networking groups and databases.
            </td>
          </tr>
          <tr>
            <td>Automated technologies or interactions</td>
            <td>as you interact with our website, app and advertisements, we may automatically
              collect technical data about your equipment, browsing actions and patterns. We
              collect this personal data by using cookies, server logs and other similar
              technologies. For further details, please see our <a
                                                                   href="${route(
                                                                     'cookies'
                                                                   )}"
                                                                   target="_blank">Cookies Site's
                Notice</a>.
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div id="quest4"><h2>4. How and why we use personal data</h2>
      <div>
        <p>Under the Data Protection Legislation, we can only use your personal data if we have a proper
          reason for doing so.</p>
        <p>4.1. <strong>Consent.</strong> Generally, we do not rely on consent as a legal basis for processing
          your personal data other than to:</p>
        <ul>
          <li>
            <p>place cookies and similar tracking technologies on your device;</p>
          </li>
          <li>
            <p>electronic marketing communication, if you requested or expressly agreed to receive
              such communication and are not our existing customer;</p>
          </li>
          <li>
            <p>to send you information about our parent
              company ${octopusLegalCompanyName}’s offer and services, if
              you have expressly agreed to; and</p>
          </li>
          <li>
            <p>respond to your enquiries</p>
          </li>
        </ul>
        <p>Where your permission is required, we will clearly ask you for such consent separately from
          the body of this privacy notice.</p>
        <p>You have the right to withdraw consent by:</p>
        <ul>
          <li>
            <p>emailing us at <a
                                 href="mailto:${octopusLegalContactEmail}">${octopusLegalContactEmail}</a>;
            </p>
          </li>
          <li>
            <p>in case of marketing emails, by using the ‘unsubscribe’ link in such marketing
              emails; or</p>
          </li>
          <li>
            <p>in case of cookies, by using the cookie preferences settings on our website.</p>
          </li>
        </ul>
        <p>4.2. <strong>Contract.</strong> We will use your personal data if we need to do it to perform our
          obligations under a contract with you, or if it is necessary for a contract which we are
          about to enter with you. For example, if we need to:</p>
        <ul>
          <li>
            <p>register you as a new customer or administer your account (e.g., set up your subscription and administer
              invoicing and payments);</p>
          </li>
          <li>
            <p>provide our services to you;</p>
          </li>
          <li>
            <p>carry out the services required which means information being passed to third parties such as the Probate
              Court, government bodies and financial institutions;</p>
          </li>
          <li>
            <p>manage our relationship with you (e.g., to respond to your enquiries or to notify you about changes to
              our services and to inform you about updating preferences); and</p>
          </li>
          <li>
            <p>provide after completion care services (e.g., technical support).</p>
          </li>
        </ul>
        <p>4.3. <strong>Legitimate interests.</strong> We may process your personal data when we (or a third party) have
          a legitimate reason to use it, so long as this is not overridden by your own rights and interests. For
          example:</p>
        <ul>
          <li>
            <p>to administer and protect our business and website (including troubleshooting, data analysis, testing,
              system maintenance, support, reporting and hosting of data);</p>
          </li>
          <li>
            <p>to interact with you professionally (e.g., if you represent our business client, supplier or
              partner);</p>
          </li>
          <li>
            <p>to manage your account and our relationship with you;</p>
          </li>
          <li>
            <p>to manage payments, fees, charges, and to collect debts which you may owe to us;</p>
          </li>
          <li>
            <p>to deal with enquiries unrelated to a contract which we have with you;</p>
          </li>
          <li>
            <p>to provide you with a free service;</p>
          </li>
          <li>
            <p>to send our updates or other electronic marketing communications if you are our current customer and
              previously purchased similar services from us, or if you represent our business client, supplier, or
              partner;</p>
          </li>
          <li>
            <p>to increase our business or promote our brand through delivering relevant website content,
              advertisements, and marketing communication to you;</p>
          </li>
          <li>
            <p>to measure or understand the effectiveness of the advertising we provide to you;</p>
          </li>
          <li>
            <p>to improve our website, products, services, marketing, and customer relationships;</p>
          </li>
          <li>
            <p>to conduct web analytics;</p>
          </li>
          <li>
            <p>for the prevention and detection of fraud; or</p>
          </li>
          <li>
            <p>for the establishment, exercise, or defence of legal claims.</p>
          </li>
        </ul>
        <p>4.4. <strong>Legal obligation.</strong> We may process your personal data to comply with our legal
          obligations. For example, to:</p>
        <ul>
          <li>
            <p>notify you about changes to our terms or privacy policy;</p>
          </li>
          <li>
            <p>address your complaint; and</p>
          </li>
          <li>
            <p>comply with a request from a competent authority.</p>
          </li>
        </ul>
      </div>
    </div>
    <div id="quest5"><h2>5. Marketing</h2>
      <div>
        <p><strong>Our marketing emails</strong></p>
        <p>We may send you emails about our services if you are our existing paying customer (on the basis of our
          legitimate interests) or, if you are our prospective paying customer, when you expressly agree to that.</p>
        <p><strong>Profiling</strong></p>
        <p>If you are our existing customer, we may use the information we have about you (such as what product you
          previously bought from us, where you live, how old you are, how you use our website and app) to make
          predictions on what other products may be of interest to you. We will use that information to make our
          marketing emails and offers relevant to you. This type of personal data use is called ‘profiling’. We will do
          that on the basis of our legitimate interests.</p>
        <p><strong>Cookies and similar technologies</strong></p>
        <p>Please refer to our website <a  href="${route(
          'cookies'
        )}" target="_blank">Cookie
          Notice</a> for more information and how we handle your personal data.</p>
        <p><strong>Data from other providers</strong></p>
        <p>If you click on our advertisement on social media (for example, Facebook) or a website or app or another
          provider (for example, a charity we partner with), that provider will share with us information about you (the
          fact that you came to our website/app from their service).</p>
        <p><strong>Right to withdraw consent or to object to processing</strong></p>
        <p>You can always ask us to stop using your personal information for marketing purposes by:</p>
        <ul>
          <li>
            <p>emailing us at <a
                                 href="mailto:${octopusLegalContactEmail}">${octopusLegalContactEmail}</a>;</p>
          </li>
          <li>
            <p>in case of marketing emails, by using the ‘unsubscribe’ link in such marketing emails; or</p>
          </li>
          <li>
            <p>in case of cookies, by using the cookie preferences settings on our website.</p>
          </li>
        </ul>
        <p>From time to time, we may ask you to confirm or update your marketing preferences.</p>
      </div>
    </div>
    <div id="quest6"><h2>6. Who we share personal data with</h2>
      <div>
        <p>We may share your information with third parties for the purposes set out in this notice.</p>
        <p><strong>Service provision</strong></p>
        <p>Where it is needed to carry out the services required we shall share your personal data to third parties such
          as the Probate Court, government bodies and financial institutions.</p>
        <p>We use Legl, a payment gateway, to process payments through our website. If you pay for our services through
          our website, you will be providing your personal data (for example, details of your payment card and billing
          address) to Legl. Please see their <a href="https://legl.com/privacy-policy" target="_blank">privacy
            policy</a>.</p>
        <p><strong>IT and technology</strong></p>
        <p>We also share data with providers of cloud-based tools and services, which we use to operate our
          business:</p>
        <ul>
          <li>
            <p>business e-mail providers;</p>
          </li>
          <li>
            <p>providers of software systems;</p>
          </li>
          <li>
            <p>e-commerce software providers;</p>
          </li>
          <li>
            <p>customer Relationship Management software providers; and</p>
          </li>
          <li>
            <p>advertising software providers.</p>
          </li>
        </ul>
        <p>We impose contractual obligations on the above providers to ensure that your personal data is protected.</p>
        <p><strong>International data transfers</strong></p>
        <p>Transfers of personal data outside the United Kingdom are subject to special rules under the Data Protection
          Legislation.</p>
        <p>If you are based outside the United Kingdom, we may receive and transfer your personal data directly to you
          to the country where you are based.</p>
        <p>We may also transfer your personal data to providers based in the European Economic Area
          (<strong>EEA</strong>). The UK Government has recognised the EEA as providing an appropriate level of
          protection to the data protection rights of individuals.</p>
        <p>We may also transfer your personal data to technology providers based in the USA. To protect your
          information, we have entered into the standard contractual data protection provisions (<strong>Standard
            Contractual Clauses</strong>) with the third parties in those territories with whom we share your data. The
          Standard Contractual Clauses are one of the appropriate data transfer safeguards specified in the UK Data
          Protection Legislation.</p>
        <p><strong>Other sharing</strong></p>
        <p>We may also:</p>
        <ul>
          <li>
            <p>share your personal data with members of our staff, contractors, freelancers and consultants;</p>
          </li>
          <li>
            <p>disclose your personal data to professional advisers (e.g. lawyers, accountants, auditors or insurers)
              who provide professional services to us;</p>
          </li>
          <li>
            <p>disclose your personal data to certain third parties if specifically requested or agreed with you (e.g.
              if you ask us to introduce you to a third party);</p>
          </li>
          <li>
            <p>disclose and exchange certain information with law enforcement agencies and regulatory bodies to comply
              with our legal obligations; and</p>
          </li>
          <li>
            <p>share some personal data with other parties, such as potential buyers of some or all of our business or
              during a re-structuring. The recipient of the information will be bound by confidentiality
              obligations.</p>
          </li>
        </ul>
      </div>
    </div>
    <div id="quest7"><h2>7. How long we keep personal data</h2>
      <div>
        <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we
          collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting
          requirements. We may retain your personal data for a longer period in the event of a complaint or if we
          reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
        <p>To determine the appropriate retention period for personal data, we consider the amount, nature and
          sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data and whether we can achieve those purposes
          through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
        <p>For example:</p>
        <ul>
          <li>
            <p>if you have a contract with us, we will keep your data for up to seven years after the end of that
              contract.</p>
          </li>
        </ul>
        <p>We may also anonymise your personal data (so that it can no longer be associated with you) for analytics,
          research, or statistical purposes, in which case we may use this information indefinitely without further
          notice to you.</p>
      </div>
    </div>
    <div id="quest8"><h2>8. Your rights</h2>
      <div class="flex flex-col gap-y-6">
        <p>You have a number of rights in relation to your personal data, which allow you to access and control your
          information in certain circumstances. You can exercise these rights free of charge, unless your request is
          manifestly unfounded or excessive (in which case we may charge a reasonable administrative fee or refuse to
          respond to such request).</p>
        <table>
          <tr>
            <th>Your right</th>
            <th>Explanation</th>
          </tr>
          <tr>
            <td>Access</td>
            <td>This enables you to receive a copy of the personal data we hold about you and to check that we are
              lawfully processing it.
            </td>
          </tr>
          <tr>
            <td>Rectification</td>
            <td>The right to require us to correct any inaccuracies in your personal data.</td>
          </tr>
          <tr>
            <td>Erasure (to be forgotten)</td>
            <td>The right to require us to delete your personal data in certain situations.</td>
          </tr>
          <tr>
            <td>Restriction of processing</td>
            <td>The right to require us to restrict processing of your personal data in certain circumstances (e.g. if
              you contest the accuracy of the data we hold).
            </td>
          </tr>
          <tr>
            <td>Data portability</td>
            <td>The right to receive, in certain situations, the personal data you provided to us, in a structured,
              commonly used and machine-readable format and/or transmit that data to a third party.
            </td>
          </tr>
          <tr>
            <td>To object</td>
            <td>The right to object at any time to your personal data being processed for direct marketing (including
              profiling) or, in certain other situations, to our continued processing of your personal data (e.g.
              processing carried out for the purpose of our legitimate interests).
            </td>
          </tr>
          <tr>
            <td>Not to be subject to automated individual decision-making</td>
            <td>The right not to be subject to a decision based solely on automated processing (including profiling)
              that produces legal effects concerning you, or similarly significantly affects you.
            </td>
          </tr>
        </table>
        <p>If you would like to exercise any of those rights, please contact us at <a class="text-blue-50 underline"
                                                                                      href="mailto:${octopusLegalContactEmail}">${octopusLegalContactEmail}</a>. Please let us know what right you want to exercise and
          the information to which your request relates.</p>
      </div>
    </div>
    <div id="quest9"><h2>9. Keeping personal data secure</h2>
      <div>
        <p>We have appropriate security measures to prevent personal data from being accidentally lost, or used or
          accessed unlawfully. We limit access to your personal data to those who have a genuine business need to access
          it. Those processing your information will do so only in an authorised manner and are subject to a duty of
          confidentiality.</p>
        <p>We also have procedures in place to deal with any suspected data security breach. We will notify you and any
          applicable regulator of a suspected data security breach where we are legally required to do so.</p>
      </div>
    </div>
    <div id="quest10"><h2>10. Complaints</h2>
      <div>
        <p>We hope that we can resolve any query or concern you may raise about our use of your information. You may
          contact us by using the contact methods set out in the How to contact us section of this policy.</p>
        <p>The Data Protection Legislation also gives you a right to lodge a complaint with a supervisory authority, in
          the country where you work, normally live or where any alleged infringement of data protection laws has
          occurred. The supervisory authority in the United Kingdom is the Information Commissioner, who may be
          contacted at <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a>,
          telephone on 0303 123 1113, or by post to: Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF.</p>
        <p>We would, however, appreciate the chance to deal with your concerns before you approach the Information
          Commissioner’s Office, so please contact us in the first instance.</p>
      </div>
    </div>
    <div id="quest11"><h2>11. How to contact us</h2>
      <div>
        <p>If you have any questions about this privacy notice, (including any requests to exercise your legal rights)
          please contact by email at <a class="text-blue-50 underline"
                                        href="mailto:${octopusLegalContactEmail}">${octopusLegalContactEmail}</a>.</p>
      </div>
    </div>
    <div id="quest12"><h2>12. Changes to this privacy policy</h2>
      <div>
        <p>This privacy policy was last updated on ${props.updatedAt}.</p>
        <p>We may change this privacy notice from time to time, when we do, we will publish the new version of the
          policy on our website. We may also inform you via email or post.</p>
      </div>
    </div>
  </article>
</div>
`;
</script>

<style scoped></style>
