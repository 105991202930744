<template>
  <LegalLayout
    :title="title"
    :content-table="contentTable"
    :buttons="buttons"
    :content="content"
  >
    <template #contentsTop>
      <a
        href="javascript:void(0)"
        class="text-ol-primary text-sm block mb-2.5 md:text-xs lg:text-sm"
        @click="scrollToTag('about')"
      >
        About Octopus Legacy
      </a>
    </template>
  </LegalLayout>
</template>

<script setup>
import LegalLayout from '@js/domains/brochure/layout/LegalLayout.vue';

const title = 'Terms & Conditions';

const buttons = [
  {
    name: 'Privacy Notice',
    href: 'privacy',
  },
  {
    name: 'Cookie Notice',
    href: 'cookies',
  },
];

const contentTable = [
  { name: 'Information about us', href: 'quest1' },
  { name: 'Your privacy', href: 'quest2' },
  { name: 'About these terms', href: 'quest3' },
  { name: 'Our intellectual property rights', href: 'quest4' },
  { name: 'How you can use our website and Services', href: 'quest5' },
  { name: 'User registration and accounts', href: 'quest6' },
  { name: 'Personal profiles', href: 'quest7' },
  { name: 'Support Hub and social networking', href: 'quest8' },
  { name: 'Your content: rules you must observe', href: 'quest9' },
  { name: 'Your content: permissions you give us', href: 'quest10' },
  { name: 'Bow Service, Will Service and LPA Service', href: 'quest11' },
  { name: 'Third-party services and websites', href: 'quest12' },
  { name: 'Our fees', href: 'quest13' },
  { name: 'Cancellation right and closing your account', href: 'quest14' },
  { name: 'If there is a problem with our products', href: 'quest15' },
  { name: 'Our website: accuracy and availability', href: 'quest16' },
  { name: 'Limits of our liability to you', href: 'quest17' },
  { name: 'If you breach these Terms', href: 'quest18' },
  { name: 'Other important terms', href: 'quest19' },
];
const config = (v) => {
  return window.config[v];
};

const scrollToTag = (tagId) => {
  const element = document.getElementById(tagId);
  const top = element.offsetTop - 120;

  window.scrollTo({ top, behavior: 'instant' });
};
const content = `<section>
            <p>
              Please read the following important terms carefully and check that
              you agree with them before placing an order on our Website.
            </p>
            <p>
              By using our website and/or services, you accept these terms and
              conditions in full. Accordingly, if you disagree with these terms
              and conditions or any part of these terms and conditions, you must
              not use our website or services.
            </p>
            <p>
              Please pay special attention to clauses 11.10 (What we do not do),
              11.11 (We do not provide legal advice), 11.12 (Limits of our
              liability in respect of our Services), and 11.3 (Your
              confirmation).
            </p>
          </section>
          <h2 id="about">About Octopus Legacy</h2>
          <p>
            Octopus Legacy is an online platform that provides space and
            resources for people who go through bereavement. We provide it
            through our <strong>Support Hub Services</strong>, which enable
            private communication between family members and friends, a family
            to-do list, coordinated support and a private online place for
            funeral information.
          </p>
          <p>
            We also help people to arrange their affairs in preparation for the
            end of their own lives through our Bow service, will writing service
            and Lasting Power of Attorney writing services.
          </p>
          <p>
            The Bow service (<strong>Bow Service</strong>) is an online
            self-assessment tool that will help you to evaluate how end-of-life
            ready you are and provide you with suggestions of how you may
            improve your preparedness.
          </p>
          <p>
            Preparing your own will can give you the peace of mind that after
            your death your assets will go where you want them to go, it allows
            you to plan your funeral and allocate specific gifts. Our will
            writing and subscription service (<strong>Will Services</strong>)
            will take you through a series of questions about you, your assets,
            and your wishes, and generate a will that is checked by our experts
            and that will be legally binding once signed and witnessed in
            accordance with legal requirements. In addition, our subscription
            option will store your will information for you and will enable you
            to update and amend your will at any time.
          </p>
          <p>
            You may need a Lasting Power of Attorney (<strong>LPA</strong>) if
            you no longer have mental capacity and cannot understand information
            relevant to a decision, retain that information long enough to make
            the decision, and use and weigh that information, or communicate the
            decision. Our Lasting Power of Attorney writing and subscription
            service (<strong>LPA Service</strong>) takes you through a series of
            questions about you and about your wishes that help you to complete
            your own LPA. In addition, our subscription option will store your
            LPA information for you and enable you to update and amend your LPA
            at any time.
          </p>

          <div id="quest1">
            <h2>1. Information about us</h2>
            <div>
              <p>
                <strong class="block"> 1.1. Who we are.</strong>
                When we say <strong>we</strong>, <strong>us</strong> or
                <strong>our</strong> in these Terms, we mean
                ${config('company_name')}, a company
                incorporated and registered in England and Wales with company
                number ${config('company_number')},
                whose registered office and main trading address is at
                ${config('company_octopus_legacy_address')}.
              </p>
              <p>
                <strong class="block"> 1.2. How to contact us.</strong>

                If you wish to contact us for any reason, including because you
                have any queries or complaints, you can contact us by using our
                website contact form, or by email, using the email address
                published on our website from time to time.
              </p>
              <p>
                <strong class="block"> 1.3. How we may contact you.</strong>

                If we need to contact you, we will do so by using the contact
                details you have provided to us when contacting us or
                registering for an account on our website.
              </p>
            </div>
          </div>
          <div id="quest2">
            <h2>2. Your privacy</h2>
            <div>
              <p>
                Under data protection legislation, we are the data controller of
                your personal data processed in connection with our website and
                our website services. We are required to provide you with
                certain information about who we are, how we process your
                personal data and for what purposes and your rights in relation
                to your personal data and how to exercise them. This information
                is provided in our
                <a
                  class="text-ol-primary underline"
                  href="${route('privacy')}"
                  target="_blank"
                  >Privacy Notice</a
                >. It is important that you read that information.
              </p>
            </div>
          </div>
          <div id="quest3">
            <h2>3. About these terms</h2>
            <div>
              <p>
                <strong class="block"> 3.1. Purpose of these Terms.</strong>

                These terms and conditions (<strong>Terms</strong>) shall govern
                your use of our website and the services we provide through our
                website, including our Support Hub Services, Bow Services, Will
                Services and LPA Services (together, the
                <strong>Services</strong>).
              </p>
              <p>
                <strong class="block">
                  3.2. Your acceptance of these Terms.</strong
                >

                By using our website and/or Services, you accept these Terms in
                full. Accordingly, if you disagree with these Terms or any part
                of these Terms, you must not use our website or Services.
              </p>
              <p>
                <strong class="block">
                  3.3. You must be at least 16 to use our website.</strong
                >

                You must be at least 16 years of age to use our website. By
                using our website or agreeing to these Terms, you warrant and
                represent to us that you are at least 16 years of age. Some of
                our website will only be available to you if you are 18 years of
                age or older (see clause 11.2, Eligibility to use our Services).
              </p>
              <p>
                <strong class="block"> 3.4. Changes to these Terms.</strong>

                We may amend these Terms from time to time, for example, to
                reflect changes in law or best practice, or to deal with changes
                we introduce to our website or Services. We will give you at
                least 15 days’ notice of any change by sending you an email with
                details of the change. The revised terms will come into effect
                on the expiry of that 15 days’ notice. If you do not agree to
                the revised terms, you can close your account on our website in
                accordance with clause 14.9 (<i>You may close your account</i>).
                If you do so:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;our contract with
                  you for any Services we provide to you will end;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we will refund to
                  you any sums paid by you in respect of Services which would
                  have been provided after the date of termination; and
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we will close your
                  account on our website.
                </li>
              </ol>
            </div>
          </div>
          <div id="quest4">
            <h2>4. Our intellectual property rights</h2>
            <div>
              <p>
                <strong class="block"> 4.1.Our copyright.</strong>

                Copyright © [2023] ${config('company_name')}.
                Subject to the express provisions of these Terms:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we, together with
                  our licensors, own and control all the copyright and other
                  intellectual property rights in our website, Services, and the
                  materials on our website; and
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;all the copyright
                  and other intellectual property rights in our website and the
                  material on our website are reserved.
                </li>
              </ol>
              <p>
                <strong class="block">4.2. Our trade marks.</strong>
                Octopus Legacy, Octopuslegacy, our logos and our other
                registered and unregistered trade marks are trade marks
                belonging to us; we give no permission for the use of these
                trade marks, and such use may constitute an infringement of our
                rights.
              </p>
              <p>
                <strong class="block"> 4.3.Third party trade marks.</strong>

                The third party registered and unregistered trade marks or
                service marks on our website are the property of their
                respective owners and, unless stated otherwise in these Terms,
                we do not endorse and are not affiliated with any of the holders
                of any such rights and as such we cannot grant any licence to
                exercise such rights.
              </p>
            </div>
          </div>
          <div id="quest5">
            <h2>5. How you can use our website and Services</h2>
            <div>
              <p>
                <strong class="block">
                  5.1. How you can use this website.</strong
                >

                Whether you are a registered user or a visitor of our website,
                you may:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;view pages from
                  our website in a web browser;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;download pages
                  from our website for caching in a web browser;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;print pages from
                  our website; and
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;use our website
                  services by means of a web browser,
                </li>
              </ol>
              <p>subject to the other provisions of these Terms.</p>
              <p>
                <strong class="block"
                  >5.2. You must only use our website as set out in these
                  Terms.</strong
                >
                Except as expressly permitted by clause 5.1 or the other
                provisions of these Terms, you must not download any material
                from our website or save any such material to your computer or
                edit or otherwise modify any material on our website.
              </p>
              <p>
                <strong class="block"> 5.3. Private use only.</strong> You may
                only use our website and Services for your own personal
                purposes, and you must not use them for business or any other
                purposes.
              </p>
              <p>
                <strong class="block"
                  >5.4. You must not republish any content.</strong
                >
                Unless you own or control the relevant rights in the material,
                you must not:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;republish material
                  from our website (including republication on another website);
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;sell, rent or
                  sub-license material from our website;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;show any material
                  from our website in public;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;exploit material
                  from our website for a commercial purpose; or
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;redistribute
                  material from our website.
                </li>
              </ol>
              <p>
                <strong class="block">
                  5.5. You may share our newsletter.</strong
                >
                Notwithstanding clause 5.4, you may redistribute our newsletter
                in print and electronic form to any person.
              </p>
              <p>
                <strong class="block">
                  5.6. We may restrict your access to our website.</strong
                >
                We reserve the right to restrict access to areas of our website,
                or our whole website, at our discretion. You must not circumvent
                or bypass, or attempt to circumvent or bypass, any access
                restriction measures on our website.
              </p>
              <p>
                <strong class="block"> 5.7. Prohibitions.</strong> You must not:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;use our website in
                  any way or take any action that causes, or may cause, damage
                  to the website or impairment of the performance, availability,
                  or accessibility of the website;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;use our website or
                  Services in any way that is unlawful, illegal, fraudulent, or
                  harmful, or in connection with any unlawful, illegal,
                  fraudulent, or harmful purpose or activity;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;use our website to
                  copy, store, host, transmit, send, use, publish or distribute
                  any material which consists of (or is linked to) any spyware,
                  computer virus, Trojan horse, worm, keystroke logger, rootkit,
                  or other malicious computer software;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;conduct any
                  systematic or automated data collection activities (including
                  without limitation scraping, data mining, data extraction and
                  data harvesting) on or in relation to our website without our
                  express written consent;
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;access or
                  otherwise interact with our website using any robot, spider or
                  other automated means, except for the purpose of search engine
                  indexing;
                </li>
                <li>
                  <strong>(f)</strong>&nbsp;&nbsp;&nbsp;&nbsp;violate the
                  directives set out in the robots.txt file for our website; or
                </li>
                <li>
                  <strong>(g)</strong>&nbsp;&nbsp;&nbsp;&nbsp;use data collected
                  from our website for any direct marketing activity (including
                  without limitation email marketing, SMS marketing,
                  telemarketing, and direct mailing).
                </li>
              </ol>
              <p>
                <strong class="block">
                  5.8. Unless expressly permitted on our website, you must not
                  use information from our website to contact people.</strong
                >
                You must not use data collected from our website to contact
                individuals, companies or other persons or entities other than
                through the means expressly permitted on the website.
              </p>
              <p>
                <strong class="block">
                  5.9. All information you supply must be accurate.</strong
                >
                You must ensure that all the information you supply to us
                through our website, or in relation to our website, is true,
                accurate, current, complete and non-misleading.
              </p>
            </div>
          </div>
          <div id="quest6">
            <h2>6. User registration and accounts</h2>
            <div>
              <p>
                <strong class="block">
                  6.1. When can you register for an account on our
                  website.</strong
                >
                To be eligible for an account on our website, you must be at
                least 16 years of age.
              </p>
              <p>
                <strong class="block">
                  6.2.How to register for an account.</strong
                >
                You may register for an account with our website via a social
                media account or by completing and submitting the account
                registration form on our website and clicking on the
                verification link in the email that the website will send to
                you.
              </p>
              <p>
                <strong class="block">
                  6.3. Only you can use your account.
                </strong>
                You must not allow any other person to use your account to
                access the website.
              </p>
              <p>
                <strong class="block">
                  6.4. You must let us know if somebody else uses your
                  account.</strong
                >
                You must notify us in writing immediately if you become aware of
                any unauthorised use of your account.
              </p>
              <p>
                <strong class="block">
                  6.5. You must not use another person’s account.</strong
                >
                You must not use any other person's account to access the
                website, unless you have that person's express permission to do
                so.
              </p>
              <p>
                <strong class="block">6.6. Account ID and password.</strong> If
                you register for an account with our website by submitting an
                account registration form, you will be asked to choose a user ID
                and password. If you register for an account via a social media
                account, you will be asked to login using your existing social
                media account details.
              </p>
              <p>
                <strong class="block">
                  6.7. Content use and impersonation.</strong
                >
                Your user ID must not be liable to mislead and must comply with
                the content rules set out in clause 9 (<i
                  >Your content: rules you must observe</i
                >). You must not use your account or user ID for or in
                connection with the impersonation of any person.
              </p>
              <p>
                <strong class="block">
                  6.8. You must not disclose your password.</strong
                >
                You must keep your password confidential. You must notify us in
                writing immediately if you become aware of any disclosure of
                your password. You are responsible for any activity on our
                website arising out of any failure to keep your password
                confidential and may be held liable for any losses arising out
                of such a failure.
              </p>
              <p>
                <strong class="block">
                  6.9. We may edit, suspend or cancel your account.</strong
                >
                We may:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;edit your account
                  details;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;temporarily
                  suspend your account; and/or
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;cancel your
                  account,
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;at any time in our
                  sole discretion, provided that if we cancel any Services you
                  have paid for and you have not breached these Terms, you will
                  be entitled to a refund of any amounts paid to us in respect
                  of those Services that were to be provided by us to you after
                  the date of such cancellation. We will give you reasonable
                  written notice of any cancellation under this clause.
                </li>
              </ol>
            </div>
          </div>
          <div id="quest7">
            <h2>7. Personal profiles</h2>
            <div>
              <p>
                <strong class="block">
                  7.1. Your profile information must be accurate.</strong
                >
                All information that you supply as part of a personal profile on
                the website must be true, accurate, current, complete and
                non-misleading.
              </p>
              <p>
                <strong class="block">
                  7.2. You must keep your profile up-to-date.</strong
                >
                You must keep your personal profile on our website up to date.
              </p>
              <p>
                <strong class="block">
                  7.3. Your profile must comply with our content rules.</strong
                >
                Personal profile information must also comply with the
                provisions of clause 5 (<i
                  >How you can use our website and Services</i
                >) and clause 9 (<i>Your content: rules you must observe</i>).
              </p>
            </div>
          </div>
          <div id="quest8">
            <h2>8. Support Hub and social networking</h2>
            <div>
              <p>
                <strong class="block">
                  8.1. Additional website features for registered users.</strong
                >
                Registered users will have access to such additional features on
                our website as we may, from time to time, determine, which may
                include:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;facilities to
                  complete a detailed personal profile on the website, to
                  publish that profile on the website and other selected
                  third-party social media sites, and to restrict the
                  publication of that profile to particular groups or
                  individuals registered on the website;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;facilities to
                  create groups, manage groups that you have created, join and
                  leave groups, and share information amongst group members;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;the facility to
                  send private messages via the website to particular groups or
                  individuals registered on the website; and
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;the facility to
                  post and publish text and media on the website and other
                  selected third-party social media sites.
                </li>
              </ol>
              <p>
                <strong class="block">
                  8.2. We are not responsible for the behaviour of other users.
                </strong>
                You acknowledge that we are not responsible for the behaviour of
                our users, either on or off the website, and we cannot guarantee
                that any information provided by a user is true, accurate,
                complete, current and not misleading. Subject to clause 17.1 (<i
                  >We do not limit or exclude anything where it would be
                  unlawful</i
                >), you will not hold us liable in respect of any loss or damage
                arising out of any user behaviour or user information.
              </p>
              <p>
                <strong class="block">
                  8.3. Other users’ posts may relate to you.</strong
                >
                You agree to the publication of posts relating to you, by
                others, on our website. You acknowledge that such posts may be
                critical or defamatory or otherwise unlawful, and, subject to
                clause 17.1 (<i
                  >We do not limit or exclude anything where it would be
                  unlawful</i
                >), you agree that you will not hold us liable in respect of any
                such posts, irrespective of whether we are aware or ought to
                have been aware of such posts.
              </p>
            </div>
          </div>
          <div id="quest9">
            <h2>9. Your content: rules you must observe</h2>
            <div>
              <p>
                <strong class="block"> 9.1. Meaning of “your content”.</strong>
                In these Terms, <strong>&quot;your content&quot;</strong> means
                all information, works and materials (including without
                limitation text, graphics, images, audio material, video
                material, audio-visual material, scripts, software and files)
                that you submit to us or our website for storage or publication
                on, processing by, or transmission via, our website.
              </p>
              <p>
                <strong class="block">
                  9.2. Your content must not infringe any person’s
                  rights.</strong
                >
                Your content must not be illegal or unlawful, must not infringe
                any person's legal rights, and must not be capable of giving
                rise to legal action against any person (in each case in any
                jurisdiction and under any applicable law).
              </p>
              <p>
                <strong class="block">
                  9.3. Your content must not be inappropriate.
                </strong>
                Your content, and the use of your content by us in accordance
                with these Terms, must not:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be libellous or
                  maliciously false;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be obscene or
                  indecent;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;infringe any
                  copyright, moral right, database right, trade mark right,
                  design right, right in passing off, or other intellectual
                  property right;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;infringe any right
                  of confidence, right of privacy or right under data protection
                  legislation;
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;constitute
                  negligent advice or contain any negligent statement;
                </li>
                <li>
                  <strong>(f)</strong>&nbsp;&nbsp;&nbsp;&nbsp;constitute an
                  incitement to commit a crime, instructions for the commission
                  of a crime or the promotion of criminal activity;
                </li>
                <li>
                  <strong>(g)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be in contempt of
                  any court, or in breach of any court order;
                </li>
                <li>
                  <strong>(h)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be in breach of
                  racial or religious hatred or discrimination legislation;
                </li>
                <li>
                  <strong>(i)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be blasphemous;
                </li>
                <li>
                  <strong>(j)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be in breach of
                  official secrets legislation;
                </li>
                <li>
                  <strong>(k)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be in breach of
                  any contractual obligation owed to any person;
                </li>
                <li>
                  <strong>(l)</strong>&nbsp;&nbsp;&nbsp;&nbsp;depict violence in
                  an explicit, graphic, or gratuitous manner;
                </li>
                <li>
                  <strong>(m)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be pornographic,
                  lewd, suggestive, or sexually explicit;
                </li>
                <li>
                  <strong>(n)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be untrue, false,
                  inaccurate, or misleading;
                </li>
                <li>
                  <strong>(o)</strong>&nbsp;&nbsp;&nbsp;&nbsp;consist of or
                  contain any instructions, advice or other information which
                  may be acted upon and could, if acted upon, cause illness,
                  injury or death, or any other loss or damage;
                </li>
                <li>
                  <strong>(p)</strong>&nbsp;&nbsp;&nbsp;&nbsp;constitute spam;
                </li>
                <li>
                  <strong>(q)</strong>&nbsp;&nbsp;&nbsp;&nbsp;be offensive,
                  deceptive, fraudulent, threatening, abusive, harassing,
                  anti-social, menacing, hateful, discriminatory, or
                  inflammatory; or
                </li>
                <li>
                  <strong>(r)</strong>&nbsp;&nbsp;&nbsp;&nbsp;cause annoyance,
                  inconvenience, or needless anxiety to any person.
                </li>
              </ol>
              <p>
                9.4. <strong>Please let us know if you spot abuse.</strong> If
                you learn of any unlawful material or activity on our website,
                or any material or activity that breaches these Terms, please
                let us know. You can let us know about any such material or
                activity by email or using our abuse reporting form.
              </p>
            </div>
          </div>
          <div id="quest10">
            <h2>10. Your content: permissions you give us</h2>
            <div>
              <p>
                <strong class="block">
                  10.1. Bow Service, Will Service and LPA Service.</strong
                >
                You grant to us a worldwide, revocable, non-exclusive,
                royalty-free permission to reproduce, store, modify and
                otherwise use any content you provide to us in connection with
                our Bow Service, Will Service and/or LPA Service solely for the
                purposes of providing the relevant Service to you. That
                permission is valid for the duration of our contract with you in
                respect of the relevant Service. The remainder of this clause 10
                does not apply to any content which you provide to us in
                connection with our Bow Service, Will Service and/or LPA
                Service.
              </p>
              <p>
                <strong class="block"> 10.2. We can use your content.</strong>
                You grant to us a worldwide, irrevocable, non-exclusive,
                royalty-free permission to reproduce, store and publish your
                content on and in relation to this website and any successor
                website, and to allow others to do the same.
              </p>
              <p>
                <strong class="block">
                  10.3. We sue people who use your content without our
                  permission.</strong
                >
                You grant to us the right to bring an action for infringement of
                the rights licensed under clause 10.2.
              </p>
              <p>
                <strong class="block"> 10.4. You may edit your content.</strong>
                You may edit your content to the extent permitted using the
                editing functionality made available on our website.
              </p>
              <p>
                <strong class="block">
                  10.5. We may delete, unpublish or edit your content if you
                  breach these Terms.</strong
                >
                Without prejudice to our other rights under these Terms, if you
                breach any provision of these Terms in any way, or if we
                reasonably suspect that you have breached these Terms in any
                way, we may delete, unpublish or edit any or all of your
                content.
              </p>
            </div>
          </div>
          <div id="quest11">
            <h2>11. Bow Service, Will Service and LPA Service</h2>
            <div>
              <p>
                <strong class="block">
                  11.1. When does this clause apply?</strong
                >
                This clause 11 applies to our Bow Service, Will Service and LPA
                Service.
              </p>
              <p>
                <strong class="block">
                  11.2. Eligibility to use our Services.</strong
                >
                We will only accept instructions from you if you:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;are over 18 years
                  of age;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;comply with these
                  Terms; and
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;have registered
                  for an account with us.
                </li>
              </ol>
              <p>
                <strong class="block">
                  11.3. When we cannot provide our Will Services to you.</strong
                >
                We cannot provide you with our Will Services if:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you want your will
                  to be governed by the law of any other jurisdiction than
                  England and Wales, or by religious laws; or
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you want to leave
                  specific instructions in your will relating to a business,
                  agricultural property or assets outside of the United Kingdom;
                  or
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you want your will
                  drafted in a language other than the English language; or
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;your wishes are
                  complex and/or involve very large or complicated estates.
                </li>
              </ol>
              <p>
                <strong class="block">
                  11.4. When we cannot provide our LPA Services to you.</strong
                >
                We cannot provide you with our LPA Services if:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you want your LPA
                  to be governed by the law of any other jurisdiction than
                  England and Wales; or
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you want your LPA
                  written in a language other than the English language; or
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;your wishes are
                  complex.
                </li>
              </ol>
              <p>
                <strong class="block"
                  >11.5. How we will accept your order for our Bow
                  Service.</strong
                >
                We will ask you to provide the information we need to calculate
                and provide you with a score on screen and by email representing
                how prepared you are for death. We will also present you with a
                list of suggested actions you may take to improve your
                preparedness. By accepting our Terms at the start of our Bow
                questionnaire you confirm that you agree to these Terms.
              </p>
              <p>
                <strong class="block"
                  >11.6. How we will accept your order for our Will Service
                  and/or LPA Service.</strong
                >
                We will ask you to provide the information we need for your will
                and/or LPA by completing our online form. Once you submit it to
                us, we will review it to check if we can accept your order, and,
                if so, will produce the will documentation and/or the LPA
                documentation for you. Our acceptance of your order will take
                place when we process your order,
                <strong>
                  at which point a contract will come into existence between you
                  and us</strong
                >
              </p>
              <p>
                <strong class="block">
                  11.7. If we cannot accept your order.</strong
                >
                If we are unable to accept your order for our Will Service
                and/or LPA Service, we will inform you of this and will not
                charge you.
              </p>
              <p>
                <strong class="block">
                  11.8. How we will provide the documents.</strong
                >
                If we can accept your order for our Will Service and/or LPA
                Service, we will make the will documentation available to you as
                digital content for download within 7 business days from the
                date you submit all the information necessary to complete your
                documents. You need to pay our fees for the Will Service and/or
                LPA Service when you place your order. You can alter the
                editable fields in the will documentation such as the names of
                beneficiaries, executors, or assets, but you are prohibited from
                changing or altering the main text on the will documentation.
              </p>
              <p>
                <strong class="block"> 11.9. Updates to the documents.</strong>
                For as long as you continue to subscribe to our Service and pay
                the relevant fees, we will store your will documentation on our
                system, and you can use the functionality of our website to make
                unlimited number of changes and updates to that documentation.
                However, we are not responsible for reviewing your will
                documentation and/or LPA documentation or sending you any
                updates or reminders in that respect. You remain responsible for
                ensuring that your will documentation remains appropriate in
                light of any changes in your personal circumstances, or the
                circumstances of anyone named in the will documentation, or in
                view of any changes in the law (including tax laws) and we
                assume no duty to you in this regard.
              </p>
              <p><strong class="block">11.10. What we do not do.</strong></p>
              <section>
                This clause sets out important exclusions in relation to our
                Will Service and our LPA Service. Please read them carefully
                before using our Services.
              </section>
              <p>
                When you use our Will Service and/or LPA Service, you should
                note that:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we do not verify
                  whether the information you provide to populate the will
                  documentation is correct, complete, legally accurate or
                  suitable and/or sufficiently detailed for your particular
                  circumstances;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;our Will Service
                  and LPA Service do not include execution of the documentation.
                  It is your responsibility to print it off and arrange for its
                  execution in accordance with the laws of England and Wales;
                  and
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we accept no
                  responsibility for, and shall have no liability for verifying:
                </li>
                <li>
                  <ol type="i" class="pl-4">
                    <li><strong>(i)</strong>&nbsp;&nbsp;&nbsp;&nbsp;your identity;</li>
                    <li>
                      <strong>(ii)</strong>&nbsp;&nbsp;&nbsp;&nbsp;your testamentary capacity;
                    </li>
                    <li><strong>(iii)</strong>&nbsp;&nbsp;&nbsp;&nbsp;your mental capacity;</li>
                    <li>
                      <strong>(iv)</strong>&nbsp;&nbsp;&nbsp;&nbsp;whether you knew, understood the
                      contents of the will/and or LPA documentation or its legal
                      effect;
                    </li>
                    <li>
                      <strong>(v)</strong>&nbsp;&nbsp;&nbsp;&nbsp;whether you have correctly
                      executed your will and/or LPA; or
                    </li>
                    <li>
                    <strong>(vi)</strong>&nbsp;&nbsp;&nbsp;&nbsp;whether there are any
                    beneficiaries who might have a claim against your estate.
                  </li>
                  </ol>
                </li>
              </ol>
              <p>
                <strong class="block">
                  11.11. We do not provide legal advice.</strong
                >
              </p>
              <section>
                This clause sets out important exclusions in relation to our Bow
                Service, Will Service and our LPA Service. Please read them
                carefully before using our Services.
              </section>
              <p>You acknowledge that:</p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;our Services are
                  dependent on the information you supply to us to generate your
                  Bow score and suggestions, populate your will documentation
                  and/or LPA documentation (as applicable), and does not claim
                  to be comprehensive or a substitute for legal advice or other
                  professional advice;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;our Services are
                  not designed to provide comprehensive advice, whether legal or
                  otherwise, on the matters that may apply in your particular
                  circumstances. Accordingly, matters which you consider to be
                  important, or which may otherwise be considered important, to
                  your particular circumstances may not have been addressed in
                  your Bow score or suggestions, your will documentation and/or
                  LPA documentation (as applicable), or may not have been
                  addressed in sufficient detail for your purposes; and
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we are not a law
                  firm, nor are we regulated by the Solicitors Regulation
                  Authority; consequently, neither our Bow Services, Will
                  Services or LPA Services create or constitute a client-lawyer
                  relationship between you and us and they cannot in any way act
                  as a substitute for obtaining your own legal advice and other
                  professional advice.
                </li>
              </ol>
              <p>
                <strong class="block">
                  11.12. Limits to our liability to you in respect of our
                  Services.</strong
                >
              </p>
              <section>
                This clause contains important information about the limits of
                our Bow Services, Will Services and LPA Services and our
                responsibility to you in respect of those Services. Please read
                it carefully.
              </section>
              <p>
                Subject to clause 17.1 (<i
                  >We do not limit or exclude anything where it would be
                  unlawful</i
                >), we do not accept any liability for:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss resulting
                  from lack or invalidity of execution of the will documentation
                  or LPA documentation, or your failure to follow our guidance
                  in that respect;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss which you
                  may suffer if your will is invalid, whether as a result of
                  lack of testamentary capacity, or knowledge, understanding or
                  approval of the contents of your will documentation or LPA
                  documentation or their legal effect, or undue influence you
                  may suffer when entering the will or LPA, or third-party
                  fraud;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss arising
                  as a result of your failure to provide accurate, complete and
                  up-to-date information when using our Services, or as a result
                  of your failure to update your will or LPA when such
                  information changes;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss arising
                  as a result of any change in the applicable laws, or your
                  personal circumstances, or the circumstances of any persons
                  named in the will and/or LPA;
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss arising
                  as a result of tax incurred or imposed on your estate by the
                  competent authorities;
                </li>
                <li>
                  <strong>(f)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss arising
                  as a result of your failure to seek legal advice in respect of
                  your death preparedness, will and/or LPA; or
                </li>
                <li>
                  <strong>(g)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any loss you, your
                  estate, or your beneficiary may suffer as a result of a claim
                  of your potential or actual beneficiary (whether or not named
                  in your will).
                </li>
              </ol>
              <p><strong class="block">11.13.Your confirmation.</strong></p>
              <section>
                This clause contains important statements to which you agree by
                using our Bow Service, Will Service and/or LPA Service. Please
                read it carefully.
              </section>
              <p>
                By downloading and/or accessing and/or reviewing and/or using
                our Will Service and/or LPA Service you confirm that:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you have fully
                  considered the provisions of these Terms, have obtained such
                  legal advice as you consider appropriate and consider such
                  provisions to be reasonable;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;you have read and
                  understood these Terms and understand that they may affect
                  your rights or responsibilities and you agree to be bound by
                  these Terms;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;any will and/or
                  LPA documentation you order and purchase will not be resold
                  and will be used by you as principal and not as an agent for
                  another party; and
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;the details you
                  provide to us when using the Bow Service, Will Service and/or
                  LPA Service will be accurate and complete and are not false,
                  misleading or a misrepresentation of your identity.
                </li>
              </ol>
            </div>
          </div>
          <div id="quest12">
            <h2>12. Third-party services and websites</h2>
            <div>
              <p>
                <strong class="block">
                  12.1. When does this clause apply.</strong
                >
                This clause 12 applies if and only if you offer to contract with
                us, or contract with us in respect of the third-party services
                further described in this clause 12.
              </p>
              <p>
                <strong class="block"> 12.2. Third-party services.</strong> Our
                website may from time-to-time feature information about services
                provided by third parties (such as solicitors, funeral providers
                or insurers) (<strong>Third-Party Providers</strong>). We may
                also offer other tools or services to allow you to communicate
                with those Third-Party Providers, but any such transactions will
                not be concluded on our website.
              </p>
              <p>
                <strong class="block"
                  >12.3. We are not a party to your contracts with Third-Party
                  Providers.</strong
                >
                Our website only provides information about the services of
                Third-Party Providers and may provide tools and services to
                allow you to communicate with them. We are not, and do not
                become, a party to any contractual relationship between you and
                the Third-Party Provider and do not mediate between you and the
                Third-Party Provider in the event of any dispute arising. You
                acknowledge and agree that you and the Third-Party Provider will
                be responsible for performing the obligations of any such
                agreements, and that we are not a party to such agreements, are
                not acting as an agent on behalf of the Third-Party Provider and
                disclaim all liability arising from or related to any such
                agreements.
              </p>
              <p>
                <strong class="block">
                  12.4. We do not recommend third party websites unless we
                  expressly say so on our website.</strong
                >
                Our website includes hyperlinks to other websites owned and
                operated by third parties. Unless otherwise expressly stated on
                our website, such hyperlinks are not recommendations.
              </p>
              <p>
                <strong class="block">
                  12.5. We are not responsible for third party websites.</strong
                >
                We have no control over third-party websites and their contents,
                and, subject to clause 17.1 (<i
                  >We do not limit or exclude anything where it would be
                  unlawful</i
                >), we accept no responsibility for them or for any loss or
                damage that may arise from your use of them.
              </p>
            </div>
          </div>
          <div id="quest13">
            <h2>13. Our fees</h2>
            <div>
              <p>
                <strong class="block">
                  13.1. Our fees are set out on our website.</strong
                >
                The fees in respect of our Services (inclusive of VAT) will be
                as set out on the website from time to time.
              </p>
              <p>
                <strong class="block">
                  13.2. You must pay our fees as directed on our
                  website.</strong
                >
                You must pay to us the fees in respect of our Services in
                advance, in cleared funds, in accordance with any instructions
                on our website.
              </p>
              <p>
                <strong class="block">13.3. We may vary our fees.</strong> We
                may vary fees from time to time by posting new fees on our
                website, but this will not affect fees for Services that have
                been previously paid. If you use any of our paid subscription
                Services (such as those described in clause 11.9 (<i
                  >Updates to the documents</i
                >), we will give you at least 15 days’ notice of any change to
                our fees by sending you an email with details of the new fees.
                The revised fees will come into effect on the expiry of that 15
                days’ notice. If you do not agree to the revised fees, you can
                close your account on our website in accordance with clause 14.2
                (<i>You may close your account</i>). If you do so:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;our contract with
                  you for any Services we provide to you will end;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we will refund to
                  you any sums paid by you in respect of Services which would
                  have been provided after the date of termination; and
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;we will close your
                  account on our website.
                </li>
              </ol>
              <p>
                <strong class="block">
                  13.4. What to do if you disagree with our fees.</strong
                >
                If you dispute any payment made to us, you must contact us
                immediately and provide full details of your claim.
              </p>
              <p>
                <strong class="block">
                  13.5. If you make an unjustified charge-back.</strong
                >
                If you make an unjustified credit card, debit card or other
                charge-back then you will be liable to pay us, within 7 days
                following the date of our written request:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;an amount equal to
                  the amount of the charge-back;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;all third-party
                  expenses incurred by us in relation to the charge-back
                  (including charges made by our or your bank or payment
                  processor or card issuer);
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;an administration
                  fee of GBP 25.00 including VAT;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;all our reasonable
                  costs, losses and expenses incurred in recovering the amounts
                  referred to in this clause (including without limitation legal
                  fees and debt collection fees); and
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;for the avoidance
                  of doubt, if you fail to recognise or fail to remember the
                  source of an entry on your card statement or other financial
                  statement, and make a charge-back as a result, this will
                  constitute an unjustified charge-back for the purposes of this
                  clause.
                </li>
              </ol>
              <p>
                <strong class="block"
                  >13.6. If you owe us any money, we can suspend or withdraw our
                  Services.
                </strong>
                If you owe us any amount under or relating to these Terms, we
                may suspend or withdraw the provision of Services to you.
              </p>
              <p>
                <strong class="block">
                  13.7. We may set off any amount that you owe to us.</strong
                >
                We may at any time set off any amount that you owe to us against
                any amount that we owe to you, by sending you written notice of
                the set-off.
              </p>
            </div>
          </div>
          <div id="quest14">
            <h2>14. Cancellation right and closing your account</h2>
            <div>
              <p>
                <strong class="block">14.1. Cooling-off period.</strong> You may
                withdraw an offer to enter into a contract with us through our
                website, or cancel a contract entered into with us through our
                website, at any time within the period:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;beginning upon the
                  submission of your offer; and
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;ending at the end
                  of 14 days after the day on which the contract is entered
                  into,
                </li>
              </ol>
              <p>subject to Section 14.2.</p>
              <p>
                You do not have to give any reason for your withdrawal or
                cancellation.
              </p>
              <p>
                <strong class="block">
                  14.2. We may start providing the services earlier.</strong
                >
                You agree that we may begin the provision of services before the
                expiry of the period referred to in Section 14.1 (<i
                  >Cooling-off period</i
                >), and you acknowledge that, if we do begin the provision of
                services before the end of that period, then:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;if the services
                  are fully performed, you will lose the right to cancel
                  referred to in Section 14.1 (<i>Cooling-off period</i>),
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;if the services
                  are partially performed at the time of cancellation, you must
                  pay to us an amount proportional to the services supplied or
                  we may deduct such amount from any refund due to you in
                  accordance with this Section 14.
                </li>
              </ol>
              <p>
                <strong class="block">
                  14.3. When the cancellation right does not apply to our
                  Services.</strong
                >
                The statutory consumer right to withdraw an offer or cancel a
                contract within the first 14 days does not apply:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;to our Support Hub
                  Services or Bow Services because we provide those Services to
                  you free of charge; or
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;to our Will
                  Services or LPA Services once they have been delivered, due to
                  the personalised nature of those Services and the bespoke
                  nature of the will and LPA documentation.
                </li>
              </ol>
              <p>
                <strong class="block">
                  14.4. How to withdraw an offer or cancel a contract.</strong
                >
                In order to withdraw an offer to contract or cancel a contract
                on the basis described in this Section 14, you must inform us of
                your decision to withdraw or cancel (as the case may be).
              </p>
              <p>
                <strong class="block">
                  14.5. Your cancellation must be sent before the deadline
                  expires.</strong
                >
                To meet the cancellation deadline, it is sufficient for you to
                send your communication concerning the exercise of the right to
                cancel before the cancellation period has expired.
              </p>
              <p>
                <strong class="block">
                  14.6. We will refund your payment less any amount for services
                  already provided.</strong
                >
                If you withdraw an offer to contract, or cancel a contract, on
                the basis described in this Section 14, you will receive a full
                refund of any amount you paid to us in respect of the offer or
                contract, except as specified in Section 14.2.
              </p>
              <p>
                <strong class="block">
                  14.7. How we will make the refund.</strong
                >
                We will refund money using the same method used to make the
                payment, unless you have expressly agreed otherwise. In any
                case, you will not incur any fees as a result of the refund.
              </p>
              <p>
                <strong class="block">
                  14.8. When we will make the refund.</strong
                >
                We will process the refund due to you as a result of a
                cancellation on the basis described in this Section 14 without
                undue delay and, in any case, within the period of 14 days after
                the day on which we are informed of the cancellation.
              </p>
              <p>
                <strong class="block">
                  14.9. You may close your account.</strong
                >
                You may cancel your account on our website using your account
                control panel on the website. You will not be entitled to any
                refund if you cancel your account in accordance with this
                Section unless you do so in the cooling-off period in accordance
                with Section 14.1 (<i>Cooling-off period</i>), or if you do so
                because you do not agree to changes to these Terms as explained
                in clause 3.4 (<i>Changes to these Terms</i>), or because you do
                not agree to changes to our subscription fees as explained in
                clause 13.3 (<i>We may vary our fees</i>).
              </p>
            </div>
          </div>
          <div id="quest15">
            <h2>15. If there is a problem with our products</h2>
            <div>
              <p>
                <strong class="block">
                  15.1. How to tell us about problems.</strong
                >
                If you have any questions or complaints about our website,
                Services or content of our website, please let us know by using
                one of the contact methods set out in clause 1.2 (<i
                  >How to contact us</i
                >). We can provide you with our complaints policy upon request.
              </p>
              <p>
                Upon receipt of a complaint, we will acknowledge it within 3
                business days of receiving it. At this point we will either
                resolve your complaint or inform you of the course of action we
                are taking to resolve it.
              </p>
              <p>
                If you are not satisfied with the resolution proposed by our
                company, you may escalate your complaint to a senior member of
                staff. The senior member of staff will review the complaint and
                the investigation, and will provide a final response.
              </p>
              <p>
                Please note that we are not regulated and you will not be able
                to make a complaint to the Legal Ombudsman. However, our
                solicitors are regulated by the Solicitors Regulation Authority
                and if your complaint is about them specifically, and the
                service they have provided which has not been resolved within 8
                weeks, you have a right to take the complaint to the Legal
                Ombudsman.
              </p>
              <p>
                For the Legal Ombudsman, visit www.legalombudsman.org.uk. Call
                0300 555 0333 between 8.30 am to 5.30 pm. Email
                enquiries@legalombudsman.org.uk. Write to Legal Ombudsman, PO
                Box 6167, Slough, SL1 0EH.
              </p>
              <p>
                Normally, you will need to bring a complaint to the Legal
                Ombudsman within six months of receiving a final written
                response from us about your complaint or within six years of the
                act or omission about which you are complaining occurring (or if
                outside of this period, within three years of when you should
                reasonably have been aware of it).
              </p>

              <p>
                <strong class="block">
                  15.2. Summary of your legal rights.</strong
                >
                We are under a legal duty to supply products that are in
                conformity with this contract. See the box below for a summary
                of your key legal rights in relation to our products. Nothing in
                these terms will affect your legal rights.
              </p>
              <section>
                This is a summary of your key legal rights. These are subject to
                certain exceptions. For detailed information please visit the
                Citizens Advice website www.adviceguide.org.uk or call 03454 04
                05 06.
                <p>
                  <strong
                    >If your product is digital content supplied for a
                    price</strong
                  >, the Consumer Rights Act 2015 says digital content must be
                  as described, fit for purpose and of satisfactory quality:<br />
                  <strong>(a)</strong> if your digital content is faulty, you're
                  entitled to a repair or a replacement;<br />
                  <strong>(b)</strong> if the fault can't be fixed, or if it
                  hasn't been fixed within a reasonable time and without
                  significant inconvenience, you can get some or all of your
                  money back; and<br />
                  <strong>(c)</strong> if you can show the fault has damaged
                  your device and we haven't used reasonable care and skill, you
                  may be entitled to a repair or compensation.
                </p>
                <p>
                  <strong
                    >If your product is services supplied for a price</strong
                  >, the Consumer Rights Act 2015 says:<br />
                  <strong>(a)</strong> you can ask us to repeat or fix a service
                  if it's not carried out with reasonable care and skill, or get
                  some money back if we can't fix it;<br />
                  <strong>(b)</strong> if you haven't agreed a price beforehand,
                  what you're asked to pay must be reasonable; and<br />
                  <strong>(c)</strong> if you haven't agreed a time beforehand,
                  it must be carried out within a reasonable time.
                </p>
              </section>
              <p>
                <strong class="block"> 15.3. Insurance.</strong> We have
                professional indemnity insurance in place. Feel free to contact
                us if you would like more details about the coverage levels.
              </p>
            </div>
          </div>
          <div id="quest16">
            <h2>16. Our website: accuracy and availability</h2>
            <div>
              <p>
                <strong class="block">
                  16.1. We do not promise that all information on our website
                  will be accurate or our website will remain available.</strong
                >
                We do not warrant or represent:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;the completeness
                  or accuracy of the information published on our website;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;that the material
                  on the website is up to date; or
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;that the website
                  or any service on the website will remain available.
                </li>
              </ol>
              <p>
                <strong class="block"
                  >16.2. We may end or change our Services.</strong
                >
                We reserve the right to discontinue or alter any or all of our
                Services, and to stop publishing our website, at any time in our
                sole discretion without notice or explanation. Save to the
                extent expressly provided otherwise in these Terms, you will not
                be entitled to any compensation or other payment upon the
                discontinuance or alteration of any Services, or if we stop
                publishing the website.
              </p>
              <p>
                <strong class="block"
                  >16.3. We do not provide legal advice.</strong
                >
                The information available on our website does not constitute
                legal advice and we do not guarantee that it is correct,
                complete, up to date or suitable to your needs.
              </p>
            </div>
          </div>
          <div id="quest17">
            <h2>17. Limits of our liability to you</h2>
            <div>
              <p>
                <strong class="block">
                  17.1. We do not limit or exclude anything where it would be
                  unlawful.</strong
                >
                Nothing in these Terms will:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude
                  any liability for death or personal injury resulting from
                  negligence;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude
                  any liability for fraud or fraudulent misrepresentation;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;limit any
                  liabilities in any way that is not permitted under applicable
                  law; or
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;exclude any
                  liabilities that may not be excluded under applicable law,
                </li>
              </ol>
              <p>
                and, if you are a consumer, your statutory rights will not be
                affected by these Terms.
              </p>
              <p>
                <strong class="block">
                  17.2. We are not responsible for your losses if our Services
                  are provided free of charge.</strong
                >
                To the extent that our website and the information and Services
                on our website are provided free of charge, we will not be
                liable for any loss or damage of any nature.
              </p>
              <p>
                <strong class="block">
                  17.3. We are not responsible for services provided to you by
                  third parties.</strong
                >
                We accept no liability in relation to any contract you enter
                into with a Third-Party Provider pursuant to clause 12 (<i
                  >Third-party Services and websites</i
                >), nor do we accept liability for the acts or omissions of any
                Third-Party Providers or other persons or parties connected with
                them. For all such services, your contract will be with the
                relevant Third-Party Provider and their terms and conditions
                will apply to the services they provide. These Terms may limit
                and/or exclude their liability to you, and you should read them
                carefully before entering into a contract for services with
                them.
              </p>
              <p>
                <strong class="block">
                  17.4. Responsibility for defective digital content.</strong
                >
                If defective digital content which we have supplied damages a
                device or digital content belonging to you and this is caused by
                our failure to use reasonable care and skill, we will either
                repair the damage or pay you compensation.
              </p>
              <p>
                <strong class="block">
                  17.5. Event beyond our reasonable control.</strong
                >
                We will not be liable to you in respect of any losses arising
                out of any event or events beyond our reasonable control.
              </p>
              <p>
                <strong class="block"> 17.6. Business losses.</strong> We will
                not be liable to you in respect of any business losses,
                including (without limitation) loss of or damage to profits,
                income, revenue, use, production, anticipated savings, business,
                contracts, commercial opportunities or goodwill.
              </p>
            </div>
          </div>
          <div id="quest18">
            <h2>18. If you breach these Terms</h2>
            <div>
              <p>
                <strong class="block"
                  >18.1. Actions we can take if you breach these Terms.</strong
                >
                Without prejudice to our other rights under these Terms, if you
                breach these Terms in any way, or if we reasonably suspect that
                you have breached these Terms in any way, we may:
              </p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;send you one or
                  more formal warnings;
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;temporarily
                  suspend your access to our website;
                </li>
                <li>
                  <strong>(c)</strong>&nbsp;&nbsp;&nbsp;&nbsp;permanently
                  prohibit you from accessing our website;
                </li>
                <li>
                  <strong>(d)</strong>&nbsp;&nbsp;&nbsp;&nbsp;block computers
                  using your IP address from accessing our website;
                </li>
                <li>
                  <strong>(e)</strong>&nbsp;&nbsp;&nbsp;&nbsp;contact any or all
                  of your internet service providers and request that they block
                  your access to our website;
                </li>
                <li>
                  <strong>(f)</strong>&nbsp;&nbsp;&nbsp;&nbsp;commence legal
                  action against you, whether for breach of contract or
                  otherwise; and/or
                </li>
                <li>
                  <strong>(g)</strong>&nbsp;&nbsp;&nbsp;&nbsp;suspend or delete
                  your account on our website; and/or
                </li>
                <li>
                  <strong>(h)</strong>&nbsp;&nbsp;&nbsp;&nbsp;suspend or end our
                  contract for Services with you.
                </li>
              </ol>
              <p>
                <strong class="block">
                  18.2. You must not circumvent our measures.</strong
                >
                Where we suspend, prohibit, or block your access to our website
                or a part of our website, you must not take any action to
                circumvent such suspension or prohibition or blocking (including
                without limitation creating and/or using a different account).
              </p>
              <p>
                <strong class="block">
                  18.3. You must compensate us if you break these Terms.</strong
                >
                If we suffer a loss as a result of your breaking these Terms,
                you must compensate us for the net costs of our losses.
              </p>
            </div>
          </div>
          <div id="quest19">
            <h2>19. Other important terms</h2>
            <div>
              <p>
                <strong class="block">
                  19.1. We may transfer our rights to someone else.</strong
                >
                We may transfer our rights and obligations under these Terms to
                another organisation. We will always tell you in writing if this
                happens and we will ensure that the transfer will not affect
                your rights under these Terms.
              </p>
              <p>
                <strong class="block">
                  19.2. You need our consent to transfer your rights to someone
                  else.</strong
                >
                You may only transfer your rights or your obligations under
                these Terms to another person if we agree to this in writing.
              </p>
              <p>
                <strong class="block">
                  19.3. Even if we delay in enforcing our contract with you, we
                  can still enforce it later.</strong
                >
                If we do not insist immediately that you do anything you are
                required to do under these Terms, or if we delay in taking steps
                against you in respect of your breaking a contract under these
                Terms with us, that will not mean that you do not have to do
                those things and it will not prevent us taking steps against you
                at a later date. For example, if you miss a payment and we do
                not chase you, but we continue to provide our Services to you,
                we can still require you to make the payment at a later date.
              </p>
              <p>
                <strong class="block">
                  19.4. If a court finds part of this contract illegal, the rest
                  will continue in force.</strong
                >
                If a provision of these Terms is determined by any court or
                other competent authority to be unlawful and/or unenforceable,
                the other provisions will continue in effect. If any unlawful
                and/or unenforceable provision of these Terms would be lawful or
                enforceable if part of it were deleted, that part will be deemed
                to be deleted, and the rest of the provision will continue in
                effect.
              </p>
              <p>
                <strong class="block">
                  19.5. obody else has any rights under your contract with
                  us.</strong
                >
                A contract under these Terms is for our benefit and your benefit
                and is not intended to benefit or be enforceable by any third
                party. The exercise of the parties' rights under a contract
                under these Terms is not subject to the consent of any third
                party.
              </p>
              <p><strong class="block"> 19.6. Disputes.</strong></p>
              <ol type="a" class="pl-4">
                <li>
                  <strong>(a)</strong>&nbsp;&nbsp;&nbsp;&nbsp;<strong
                    >If you have a complaint.</strong
                  >
                  We will try to resolve any disputes with you quickly and
                  efficiently. If you are unhappy with our Services or any other
                  matter, please contact us as soon as possible using one of the
                  contact methods set out in clause 1.2 (<i>How to contact us</i
                  >).
                </li>
                <li>
                  <strong>(b)</strong>&nbsp;&nbsp;&nbsp;&nbsp;<strong
                    >Alternative dispute resolution.</strong
                  >
                  If you and we cannot resolve a dispute using our internal
                  complaint handling procedure, we will let you know that we
                  cannot settle the dispute with you. We will also give you
                  certain information required by law about resolving disputes
                  through alternative dispute resolution. Alternative dispute
                  resolution is a process where an independent body considers
                  the facts of a dispute and seeks to resolve it, without you
                  having to go to court.
                </li>
              </ol>
              <p>
                <strong class="block">
                  19.7. Which laws apply to this contract and where you may
                  bring legal proceedings.</strong
                >
                Our Terms are governed by English law and you can bring legal
                proceedings in the English courts. This provision will not have
                the effect of depriving you of the protection afforded to you by
                mandatory provisions of the applicable laws regulating the
                choice of the governing law and/or jurisdiction in consumer
                contracts. For example, if you live in Scotland, you can bring
                legal proceedings in either the Scottish or the English courts.
                If you live in Northern Ireland, you can bring legal proceedings
                in either the Northern Irish or the English courts.
              </p>
            </div>
          </div>
        `;
</script>

<style scoped></style>
