<template>
  <section class="text-ol-dark-ink-200">
    <div class="lg:text-center">
      <div class="flex flex-col gap-7 mb-14">
        <h2 class="text-4xl 2xl:text-5xl font-bold">Join the team</h2>
        <p class="text-lg xl:text-xl max-w-screen-lg mx-auto">
          Explore our open roles for working remotely, from the office or
          somewhere in between.
        </p>
      </div>
    </div>
    <div
      class="flex flex-col gap-y-3 md:flex-row md:gap-x-5 justify-between items-start md:items-center mb-8 w-full max-w-screen-xl mx-auto"
    >
      <h4>Open Roles ({{ jobCount }})</h4>
      <div
        class="flex flex-col flex-grow self-stretch xl:max-w-128 gap-y-3 md:flex-row md:gap-x-5 justify-between items-start md:items-center"
      >
        <CareerFilter
          v-model="locationFilter"
          :options="locations"
          placeholder="All location"
          selector-label="location filter"
        />

        <CareerFilter
          v-model="departmentFilter"
          :options="departments"
          placeholder="All departments"
          selector-label="department filter"
        />
      </div>
    </div>

    <div v-if="filteredRoles.length">
      <div
        v-for="[department, roles] in filteredRoles"
        class="flex flex-col gap-y-5 xl:gap-y-6 mb-14 last:mb-0 w-full max-w-screen-xl mx-auto"
      >
        <h1 class="font-bold text-2xl md:text-3xl">
          {{ department }}
        </h1>
        <JobCard v-for="role in roles" :role="role" />
      </div>
    </div>
    <div v-else>
      <h3
        class="text-xl md:text-2xl text-black font-medium md:text-center mt-8"
      >
        There are no open roles right now. You can still send us an email to
        <a :href="`mailto:${companyEmail}`" class="btn text-ol-purple">
          {{ companyEmail }} </a
        >.
      </h3>
    </div>
  </section>
</template>

<script>
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import Arrow from '@components/Icon/Arrow.vue';
import JobCard from '@js/domains/brochure/components/careers/JobCard.vue';
import { groupBy } from '@js/utils/groupBy.js';
import { computed, ref } from 'vue';
import CareerFilter from '@js/domains/brochure/components/careers/CareerFilter.vue';

export default {
  components: {
    CareerFilter,
    Arrow,
    ButtonRounded,
    JobCard,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const parsedJobs = props.jobs.map((job) => JSON.parse(job));

    const locationFilter = ref('');
    const departmentFilter = ref('');

    const locations = new Set(parsedJobs.map((j) => j.location.split(' ')[0]));

    const groupedRoles = groupBy(parsedJobs, 'departmentName');

    const filteredRoles = computed(() => {
      return Object.entries(groupedRoles)
        .filter(([department]) => byDepartment(department))
        .map(([department, roles]) => filterByLocation(department, roles))
        .filter(Boolean);
    });

    const departments = Object.keys(groupedRoles);

    const companyEmail = window.config.contact_email;

    function byDepartment(department) {
      return departmentFilter.value
        ? department === departmentFilter.value
        : true;
    }

    function filterByLocation(department, roles) {
      const filteredRoles = roles.filter((r) =>
        r.location.includes(locationFilter.value)
      );

      return filteredRoles.length > 0 ? [department, filteredRoles] : null;
    }

    const jobCount = computed(() =>
      filteredRoles.value.reduce((acc, curr) => acc + curr[1].length, 0)
    );

    return {
      groupedRoles,
      parsedJobs,
      companyEmail,
      locations,
      departments,
      locationFilter,
      departmentFilter,
      filteredRoles,
      jobCount,
    };
  },
};
</script>

<style scoped>
section {
  @apply bg-transparent;
}
</style>
