<template>
  <select
    :aria-label="selectorLabel"
    v-model="selectedValue"
    class="px-4 py-3 rounded-md border-2 border-ol-dark bg-ol-cream self-stretch flex-1 text-sm text-ol-dark-ink-200 flex-grow"
  >
    <option selected value="">
      {{ placeholder }}
    </option>
    <option v-for="option in options" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script setup>
defineProps({
  selectorLabel: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  options: {
    type: [Array, Set],
    required: true,
  },
});

const selectedValue = defineModel({ type: String });
</script>

<style scoped></style>
