<template>
  <section class="b-corp-section">
    <div class="b-corp-content-container">
      <div class="b-corp-image-container">
        <img class="md:h-[467px] lg:h-auto" :src="url" alt="b corp logo" />
      </div>
      <div class="b-corp-content">
        <h1 class="text-4xl 2xl:text-5xl font-bold">Proud to be a B Corp</h1>
        <div class="flex flex-col text-lg xl:text-2xl font-medium gap-y-6">
          <p>
            We are very proud to announce we are officially B Corp accredited.
          </p>
          <p>
            To gain B Corp certification, a company needs to meet high standards
            and prove it has a positive impact not just on its workers and
            customers, but also on the wider community and environment.
          </p>
        </div>
        <a
          target="_blank"
          class="text-base 2xl:text-xl underline"
          href="https://www.bcorporation.net/en-us/"
        >
          Learn more about what it means to be B Corp Certified
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BCorp.vue',

  setup() {
    const url = new URL('/public/theme/img/B-Corp-logo.png', import.meta.url);
    return { url };
  },
};
</script>

<style lang="scss" scoped>
.b-corp-section {
  @apply flex
  flex-col md:flex-row
  justify-center items-center
  gap-x-12
  py-20 md:py-28 xl:py-36
  px-5 xs:px-8 md:px-7 lg:px-16 xl:px-28 2xl:px-32
  text-white bg-ol-dark-ink-200 rounded-3xl;
}

.b-corp-content-container {
  @apply flex
  flex-col lg:flex-row
  justify-center items-center
  max-w-screen-xl mx-auto
  lg:gap-x-20 xl:gap-x-28
  gap-y-14 xs:gap-y-20 md:gap-y-14;
}

.b-corp-content {
  @apply flex flex-col
  justify-center
  self-stretch
  gap-y-12;
}

.b-corp-image-container {
  @apply bg-ol-secondary-20 rounded-2xl
  flex justify-center items-center
  w-full lg:w-1/2 lg:max-w-[358px]
  md:h-160 lg:h-full
  px-20
  py-14;
}
</style>
