<template>
  <div class="text-ol-dark-ink-200 flex flex-col gap-7">
    <h2 class="text-3xl md:text-4xl 2xl:text-5xl font-bold">
      {{ heading }}
    </h2>
    <p
      class="text-base md:text-lg xl:text-xl text-balance md:text-wrap 2xl:text-balance max-w-screen-lg mx-auto"
    >
      {{ paragraph }}
    </p>
    <slot name="button" />
  </div>
</template>

<script>
import Container from '@js/components/Core/Container.vue';

export default {
  name: 'HeadingWithDescription',
  components: {
    Container,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
