<template>
  <div class="p-7 md:p-10 flex flex-col bg-white rounded-ol-card">
    <OpenQuote class="mb-2" />
    <h1 class="text-2xl text-ol-dark-ink-200 font-bold mb-5">
      {{ testimonial.title }}
    </h1>
    <p class="mb-20 font-medium text-ol-ashes">
      {{ testimonial.review }}
    </p>
    <EmployeeCard
      :name="testimonial.name"
      :image="testimonial.image"
      :job-title="testimonial.jobTitle"
    />
  </div>
</template>

<script>
import OpenQuote from '@components/Icon/OpenQuote.vue';
import AvatarIcon from '@components/AvatarIcon.vue';
import EmployeeCard from '@components/Brochure/EmployeeCard.vue';

export default {
  components: { EmployeeCard, AvatarIcon, OpenQuote },
  props: {
    testimonial: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
