<template>
  <div class="story-container" :class="[rowDirection, columnDirection]">
    <figure class="story-container__image">
      <img
        :alt="imageAltText"
        :src="image"
        class="aspect-square object-cover rounded-3xl w-full h-auto"
      />
      <figcaption
        class="text-shadow text-white text-2xs w-2/3 xs:w-[unset] text-right lg:text-xs absolute right-[7%] bottom-[7%] text-shadow"
      >
        <span> {{ figureCaption }}</span>
      </figcaption>
    </figure>

    <div class="story-container__body">
      <h3 class="text-3xl sm:text-4xl md:text-3xl xl:text-4xl">
        <slot name="title">
          {{ title }}
        </slot>
      </h3>
      <div
        class="text-base xs:text-lg md:text-base lg:text-xl whitespace-pre-wrap"
      >
        <slot name="body">
          {{ body }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  body: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  image: {
    type: URL,
    default: null,
  },
  imageAltText: {
    type: String,
    default: null,
  },
  primaryCta: {
    type: Object,
    default: null,
  },
  secondaryCta: {
    type: Object,
    default: null,
  },
  figureCaption: {
    type: String,
    default: null,
  },
  rowReversed: {
    type: Boolean,
    default: false,
  },
  columnReversed: {
    type: Boolean,
    default: false,
  },
});

const rowDirection = computed(() =>
  props.rowReversed ? 'md:flex-row-reverse' : 'md:flex-row'
);
const columnDirection = computed(() =>
  props.columnReversed ? 'flex-col-reverse' : 'flex-col'
);
</script>

<style lang="scss" scoped>
.story-container {
  @apply flex max-w-content mx-auto
  justify-center items-center
  gap-y-10 sm:gap-y-14
  md:gap-x-20 lg:gap-x-28
  xl:gap-x-32
  pt-20 sm:pt-24 md:pt-28 lg:pt-36
  px-7 md:px-16 xl:px-0;

  &__image {
    @apply relative flex-1 self-stretch;
  }

  &__body {
    @apply flex flex-col gap-6 flex-1;
  }
}

.text-shadow {
  > span {
    @apply relative;
    z-index: 1;
  }

  &::before {
    content: '';
    width: calc(100% + 1rem);

    @apply absolute
    -left-2
    -top-2
    z-0
    p-4
    h-full
    opacity-40
    bg-ol-grey;
    filter: blur(1.2rem);
  }
}
</style>
