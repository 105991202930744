<template>
  <OLHeroSection
    :title="title"
    :sub-title="subTitle"
    data-testid="hero-section"
    border-css="!p-0 bg-[unset]"
    use-new
    skinny-padding
  >
    <template #body>
      <div class="flex flex-col gap-5 sm:flex-row mt-5">
        <OLButton
          v-for="button in buttons"
          variant="special"
          :link-to="route(button.href)"
          class="!w-full sm:!w-fit"
        >
          {{ button.name }}
          <Arrow />
        </OLButton>
      </div>
    </template>
  </OLHeroSection>
  <slot name="content">
    <div class="container max-w-content mt-10 md:mt-28">
      <div
        class="flex flex-col md:flex-row md:gap-x-20 lg:gap-x-48 pb-20 lg:pb-28 xl:pb-36"
      >
        <div class="md:w-3/4 md:px-4 order-2 md:order-1">
          <article class="legal-cms">
            <div v-safe-html="content" />
          </article>
        </div>
        <div class="relative order-1 md:order-2 md:w-1/4 mb-10 md:mb-0">
          <div class="sticky top-24 lg:pb-10 lg:h-screen lg:overflow-y-auto">
            <span class="text-ol-dark-ink-200 text-2xl md:text-xl lg:text-2xl">
              Table of Contents
            </span>

            <div class="mt-8">
              <slot name="contentsTop" />
              <ol
                class="ml-4 font-medium text-sm md:text-xs flex flex-col gap-2.5 lg:text-sm"
              >
                <li v-for="(item, index) in contentTable">
                  <a
                    href="javascript:void(0)"
                    class="!text-ol-dark-ink-200 break-long-words cursor-pointer hover:underline"
                    @click="scrollToTag(item.href)"
                  >
                    <span>
                      {{ index + 1 + '. ' }}
                    </span>
                    {{ item.name }}
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </slot>
  <SectionCta />
</template>
<script setup>
import OLHeroSection from '@components/OLHeroSection.vue';
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';
import SectionCta from '@components/Footer/SectionCta.vue';

defineProps({
  title: { type: String, default: '' },
  subTitle: { type: String, default: '' },
  content: { type: String, default: '' },
  contentTable: { type: Array, default: () => [] },
  buttons: { type: Array, default: () => [] },
});

const scrollToTag = (tagId) => {
  const element = document.getElementById(tagId);
  const top = element.offsetTop - 120;

  window.scrollTo({ top, behavior: 'instant' });
};
</script>
<style lang="postcss">
.legal-cms {
  @apply text-lg text-dark-ashes-100 font-normal break-words;

  h2 {
    @apply mt-20 text-ol-dark-ink-200 font-bold lg:text-3xl text-2xl mb-6;
  }

  p {
    @apply mt-4 text-ol-dark-100 text-base font-medium;
  }

  li::marker {
    @apply mt-4 text-ol-dark-100 text-base font-bold;
  }

  li {
    @apply mt-2 text-base;
  }

  a {
    @apply text-ol-primary-200 underline;
  }

  a:hover {
    @apply text-ol-dark-ink-200 no-underline;
  }

  ol {
    @apply list-decimal ml-6;
  }

  section {
    @apply bg-ol-secondary-20 rounded-xl text-base  mt-6 p-8;
  }

  table {
    @apply border-x  border-x-ol-secondary-20 text-ol-dark table-fixed w-full;
  }

  th {
    @apply font-bold text-white lg:text-nowrap p-5 lg:px-8 text-left;

    &:first-of-type {
      @apply border-r-2 border-r-ol-secondary-20 rounded-tl-xl;
    }

    &:nth-of-type(2) {
      @apply rounded-tr-xl;
    }
  }

  tr {
    &:first-of-type {
      @apply bg-ol-plum;
    }

    &:not(:first-of-type):nth-of-type(odd) {
      @apply bg-ol-secondary-20;
    }

    &:last-of-type > td:nth-last-of-type(1) {
      @apply border-b border-b-ol-secondary-20;
    }

    &:last-of-type > td:nth-last-of-type(2) {
      @apply border-b border-b-ol-secondary-20;
    }
  }

  td {
    @apply py-5;

    &:nth-of-type(even) {
      @apply px-5 lg:pr-8;
    }

    &:nth-of-type(odd) {
      @apply px-5 lg:pl-8;
    }

    &:first-of-type {
      @apply border-r border-r-ol-secondary-20;
    }
  }
}
</style>
