<template>
  <section class="below-fold">
    <h3 class="below-fold__text" data-testid="below-fold-text">
      <slot />
    </h3>
  </section>
</template>

<style lang="scss" scoped>
.below-fold {
  @apply flex
  justify-center
  bg-ol-cream
  w-full
  px-3 xs:px-8 sm:px-7 lg:px-36 xl:px-52
  py-20 xs:py-28 lg:py-36
  max-w-content mx-auto;
}

.below-fold__text {
  @apply lg:text-center
  text-xl xs:text-2xl lg:text-3xl xl:text-4xl
  max-w-[70rem];
}
</style>
